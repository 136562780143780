import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

import { RESIZE_DEBOUNCE_MS } from '../../constants';
import { useViewport } from '../../context/viewport/context';

/**
 * A generic hook to calculate the maximum allowed width for resizable sections.
 * This hook is used by both the list resizer and extended content resizer.
 *
 * @param getMaxWidthFn Function to calculate the maximum width based on viewport width
 * @returns The maximum allowed width for the section
 */
export const useMaxWidth = (getMaxWidthFn: (viewportWidth: number) => number) => {
	const [maxWidth, setMaxWidth] = useState(Infinity);
	const { requestViewportBoundingClient } = useViewport();

	const setMaxWidthFromViewport = useCallback(() => {
		const viewportBoundingClient = requestViewportBoundingClient({ withTimelineOffset: false });

		if (viewportBoundingClient) {
			const newMaxWidth = getMaxWidthFn(viewportBoundingClient.width);
			setMaxWidth(newMaxWidth);
		}
	}, [requestViewportBoundingClient, getMaxWidthFn]);

	useEffect(() => {
		setMaxWidthFromViewport();
	}, [setMaxWidthFromViewport]);

	useEffect(() => {
		const onWindowResize = debounce(() => {
			setMaxWidthFromViewport();
		}, RESIZE_DEBOUNCE_MS);

		// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
		window.addEventListener('resize', onWindowResize);

		return () => {
			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			window.removeEventListener('resize', onWindowResize);
			onWindowResize.cancel();
		};
	}, [setMaxWidthFromViewport]);

	return maxWidth;
};
