import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noResults: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.no-results',
		defaultMessage: 'No results',
		description:
			'After the user searches for an issue in the change parent issue menu of the simple timeline and there are no results',
	},
	optionsAvailable: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.options-available',
		defaultMessage: '{count} {count, plural, one {option} other {options}} available.',
		description:
			'After the user searches for an issue in the change parent issue menu of the simple timeline and there are results available',
	},
	searchIssues: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.search-issues',
		defaultMessage: 'Search issues',
		description:
			'Placeholder for the search input field in the change parent issue menu of the simple timeline',
	},
	searchDescribedBy: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.search-described-by',
		defaultMessage: 'Typing any character in this field will update the search',
		description:
			'Label to describe instructions for the search input field in the change parent issue menu of the simple timeline',
	},
	inputLabelWithLoading: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.input-label-with-loading',
		defaultMessage: 'Currently loading issues.',
		description:
			'Label for the search input field in the change parent issue menu of the simple timeline when the issues are loading',
	},
	searchIconLabel: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.search-icon-label',
		defaultMessage: 'Search icon',
		description:
			'Label for the search icon in the search input field in the change parent issue menu of the simple timeline',
	},
	loading: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.loading',
		defaultMessage: 'Loading',
		description: 'Label for loading spinner',
	},
	optionLabel: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.option-label',
		defaultMessage: 'Issue {type} {key} {summary}',
		description:
			'Aria label for each option in the menu group list of issues in the change parent issue menu of the simple timeline',
	},
	inputLabelWithLoadingIssueTermRefresh: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.input-label-with-loading-issue-term-refresh',
		defaultMessage: 'Currently loading work item.',
		description:
			'Label for the search input field in the change parent issue menu of the simple timeline when the issues are loading',
	},
	searchIssuesIssueTermRefresh: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.search-issues-issue-term-refresh',
		defaultMessage: 'Search work items',
		description:
			'Placeholder for the search input field in the change parent issue menu of the simple timeline',
	},
	optionLabelIssueTermRefresh: {
		id: 'roadmap.timeline-table-kit.change-parent-menu.option-label-issue-term-refresh',
		defaultMessage: '{type} {key} {summary}',
		description:
			'Aria label for each option in the menu group list of issues in the change parent issue menu of the simple timeline',
	},
});
