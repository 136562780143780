/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import { css, jsx } from '@compiled/react';

import { N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// Base styles for the divider should visually match the Resizer component
const dividerBaseStyles = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
	// Same width as the Resizer for visual consistency
	width: token('space.100', '8px'),

	// Actual divider line as pseudo-element
	'&::after': {
		position: 'absolute',
		display: 'block',
		content: '""',
		top: 0,
		width: token('space.025', '2px'),
		height: '100%',
		backgroundColor: token('color.border', N30),
		pointerEvents: 'none',
	},
});

export type ExtendedContentDividerProps = {
	/** Position from left edge in pixels */
	left: number;
	/** Height of the divider in pixels */
	height: number;
	/** Whether the divider should be visible (opacity 1) or hidden (opacity 0) */
	isVisible: boolean;
};

/**
 * ExtendedContentDivider renders a vertical divider between columns in the extended content area.
 *
 * The divider is positioned absolutely, with its left position determined by the column layout.
 * When columns extend beyond the visible area, dividers are hidden with opacity: 0.
 *
 * The divider has a wider interactive area (8px) with the actual divider line at the left edge of this area,
 * ensuring the line appears exactly at the boundary between cells, and the hit area extends rightward.
 * This prepares for future resize functionality without overlapping adjacent cell content.
 *
 * @example
 * ```tsx
 * <ExtendedContentDivider
 *   left={120}
 *   height={40}
 *   isVisible={true}
 * />
 * ```
 */
export const ExtendedContentDivider = ({
	left,
	height,
	isVisible,
}: ExtendedContentDividerProps) => {
	return (
		<div
			css={dividerBaseStyles}
			style={{
				left: `${left}px`,
				height: `${height}px`,
				opacity: isVisible ? 1 : 0,
			}}
			role="separator"
			aria-orientation="vertical"
			// Adding data attributes for future resize functionality
			// data-column-index={columnIndex}
			// data-divider-resizable={Boolean(onResize)}
		/>
	);
};
