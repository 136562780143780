import React, {
	useRef,
	useMemo,
	useCallback,
	useEffect,
	useState,
	memo,
	type ChangeEvent,
} from 'react';
import { mergeRefs } from 'use-callback-ref';
import SearchIcon from '@atlaskit/icon/core/search';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Box, xcss, Text } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TriggerProps } from '@atlaskit/tooltip';
import { ScreenReaderText } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Option, Action, Props } from './types.tsx';

const ChangeParentMenu = ({
	searchTerm = '',
	options,
	actions,
	isLoading = false,
	isLocalFilterEnabled = true,
	inputRef,
	onOptionSelect,
	onSearchChange,
}: Props) => {
	const { formatMessage } = useIntl();

	const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

	const [query, setQuery] = useState<string>(
		fg('jwm_timeline_meatball_reparent_menu') ? searchTerm : '',
	);
	const ref = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (fg('jwm_timeline_meatball_reparent_menu')) {
			setQuery(searchTerm);
			if (!isLoading) {
				setFilteredOptions(options);
			}
		} else if (!isLoading) {
			setQuery('');
			setFilteredOptions(options);
		}
	}, [actions, options, isLoading, searchTerm]);

	useEffect(() => {
		if (!inputRef) {
			ref.current?.focus();
		}
	}, [inputRef]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			if (fg('jwm_timeline_meatball_reparent_menu')) {
				onSearchChange?.(value);
				if (isLocalFilterEnabled) {
					const lowerCaseValue = String(value.toLocaleLowerCase());
					setQuery(value);
					setFilteredOptions(
						options.filter((option) =>
							`${option.key} ${option.summary}`.toLocaleLowerCase().includes(lowerCaseValue),
						),
					);
				}
			} else {
				const lowerCaseValue = String(value.toLocaleLowerCase());
				setQuery(value);
				setFilteredOptions(
					options.filter((option) =>
						`${option.key} ${option.summary}`.toLocaleLowerCase().includes(lowerCaseValue),
					),
				);
			}
		},
		[isLocalFilterEnabled, onSearchChange, options],
	);

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Escape' && query !== '') {
				setQuery('');
				setFilteredOptions(options);
			}
		},
		[options, query],
	);

	const renderActionButton = useCallback(
		(action: Action, tooltipProps?: TriggerProps) => (
			<ButtonItem
				{...tooltipProps}
				key={action.label}
				onClick={action.callback}
				{...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-reparent-option-clicked' }
					: {})}
			>
				<Box xcss={optionContainerStyles}>{action.label}</Box>
			</ButtonItem>
		),
		[],
	);

	const renderOptionButton = useCallback(
		(option: Option, tooltipProps?: TriggerProps) => (
			<ButtonItem
				{...tooltipProps}
				aria-label={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.optionLabelIssueTermRefresh
						: messages.optionLabel,
					{
						type: option.issueType,
						key: option.key,
						summary: option.summary,
					},
				)}
				key={option.id}
				onClick={(e) => onOptionSelect(e, option)}
				{...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-reparent-option-clicked' }
					: {})}
			>
				<Box xcss={optionContainerStyles}>
					<img src={option.iconUrl} alt={option.issueType} />
					<Box as="span">{option.key}</Box>
					<Box as="span" xcss={optionStyles}>
						{option.summary}
					</Box>
				</Box>
			</ButtonItem>
		),
		[formatMessage, onOptionSelect],
	);

	const hasActions = actions && actions.length !== 0;
	const hasOptions = filteredOptions.length !== 0;
	const renderedOptions = useMemo(() => filteredOptions.slice(0, 20), [filteredOptions]);
	const searchIssueMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? messages.searchIssuesIssueTermRefresh
		: messages.searchIssues;

	const inputLabelWithLoadingMessage = expVal(
		'issue-terminology-refresh-m2-replace',
		'isEnabled',
		false,
	)
		? messages.inputLabelWithLoadingIssueTermRefresh
		: messages.inputLabelWithLoading;

	return (
		<Box xcss={containerStyles}>
			<MenuGroup spacing="compact">
				<Box xcss={inputWrapperStyles}>
					<Textfield
						ref={inputRef ? mergeRefs([inputRef, ref]) : ref}
						value={query}
						aria-label={
							isLoading
								? formatMessage(inputLabelWithLoadingMessage)
								: formatMessage(searchIssueMessage)
						}
						aria-describedby="timeline-change-parent-menu-search-instructions"
						placeholder={formatMessage(searchIssueMessage)}
						elemBeforeInput={
							<Box xcss={iconContainerStyles}>
								<SearchIcon label="" color={token('color.icon')} />
							</Box>
						}
						role="searchbox"
						onChange={handleChange}
						onKeyDown={handleKeyDown}
					/>
					<ScreenReaderText as="span" id="timeline-change-parent-menu-search-instructions">
						{formatMessage(messages.searchDescribedBy)}
					</ScreenReaderText>
				</Box>

				{isLoading ? (
					<Section>
						<Box xcss={emptyWrapperStyles}>
							<Spinner interactionName="load" label={formatMessage(messages.loading)} />
						</Box>
					</Section>
				) : (
					<>
						<ScreenReaderText role="status" aria-atomic="true">
							{formatMessage(messages.optionsAvailable, {
								count: filteredOptions.length + (actions ? actions.length : 0),
							})}
						</ScreenReaderText>
						<Box xcss={optionListStyles} role="presentation">
							{/* eslint-disable-next-line no-nested-ternary  -- cleanup chronos_a11y_fixes_jtran2 */}
							{!hasOptions && !hasActions ? (
								<Section>
									<Box xcss={[emptyWrapperStyles, noOptionStyles]}>
										<Text color="color.text.subtle">{formatMessage(messages.noResults)}</Text>
									</Box>
								</Section>
							) : fg('chronos_a11y_fixes_jtran2') ? (
								<>
									{hasOptions ? (
										<Section isList>
											{renderedOptions.map((option) =>
												option.summary.length > 16 ? (
													<Tooltip key={option.id} content={option.summary}>
														{(tooltipProps) => renderOptionButton(option, tooltipProps)}
													</Tooltip>
												) : (
													renderOptionButton(option)
												),
											)}
										</Section>
									) : null}
								</>
							) : (
								<>
									{hasOptions
										? renderedOptions.map((option) =>
												option.summary.length > 16 ? (
													<Tooltip key={option.id} content={option.summary}>
														{(tooltipProps) => renderOptionButton(option, tooltipProps)}
													</Tooltip>
												) : (
													renderOptionButton(option)
												),
											)
										: null}
								</>
							)}
						</Box>
						{/* eslint-disable-next-line no-nested-ternary */}
						{hasActions ? (
							fg('jsw_roadmaps_timeline-fix-a11y-rain') ? (
								<Box xcss={[hasOptions && borderStyles]}>
									{actions.map((action) =>
										action.label.length > 16 ? (
											<Tooltip key={action.label} content={action.label}>
												{(tooltipProps) => renderActionButton(action, tooltipProps)}
											</Tooltip>
										) : (
											renderActionButton(action)
										),
									)}
								</Box>
							) : (
								actions.map((action) =>
									action.label.length > 16 ? (
										<Tooltip key={action.label} content={action.label}>
											{(tooltipProps) => renderActionButton(action, tooltipProps)}
										</Tooltip>
									) : (
										renderActionButton(action)
									),
								)
							)
						) : null}
					</>
				)}
			</MenuGroup>
		</Box>
	);
};

const borderStyles = xcss({
	borderTop: '2px solid',
	borderColor: 'color.border',
});

const containerStyles = xcss({
	width: '275px',
	backgroundColor: 'elevation.surface',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: '5px',
});

const inputWrapperStyles = xcss({
	padding: 'space.150',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	borderBottom: '2px solid',
	borderColor: 'color.border',
});

const iconContainerStyles = xcss({
	display: 'flex',
	padding: 'space.100',
	paddingRight: 'space.025',
});

const optionListStyles = xcss({
	maxHeight: '252px',
	overflow: 'auto',
});

const optionContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	gap: 'space.150',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});

const optionStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const emptyWrapperStyles = xcss({
	display: 'flex',
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: 'space.100',
	marginBottom: 'space.100',
});

const noOptionStyles = xcss({
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
});

export default memo<Props>(ChangeParentMenu);
