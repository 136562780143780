import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Color } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ColorMenu as RoadmapColorMenu } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/context-menu/color/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ChangeColorMenu = ({ color, setInitialFocusRef, setCloseMenu, onUpdate }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onUpdateColor = useCallback(
		(event: MouseEvent | KeyboardEvent, updatedColor: Color) => {
			if (updatedColor !== color) {
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'option',
				});

				fireUIAnalytics(analyticsEvent, 'changeColorOption');
				traceUFOPress('timeline-change-color-option-clicked', event.timeStamp);

				onUpdate(updatedColor, analyticsEvent);
				setCloseMenu(event);
			}
		},
		[color, createAnalyticsEvent, onUpdate, setCloseMenu],
	);

	return (
		<RoadmapColorMenu
			title={formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.changeColorMenuTitleIssueTermRefresh
					: messages.changeColorMenuTitle,
			)}
			color={color}
			initialFocusRef={setInitialFocusRef}
			onUpdateColor={onUpdateColor}
		/>
	);
};

export default ChangeColorMenu;
