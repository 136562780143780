import { useLayoutEffect, useMemo, useRef } from 'react';

import type { ClassName } from './types';
import {
	classNames,
	getIndexByRule,
	getIndexBySelector,
	styles,
	toRule,
	toSelector,
	toSelectors,
} from './utils';

/* This hook can be used to style components outside the React lifecycle, and without the aid
 * of libraries like compiled. It is achieved by inserting styles directly into a `<style>` tag
 * in the `<head>` of the document (similar to popular CSS-in-JS frameworks).
 *
 * This is necessary to provide a performant experience for high-frequency interactions that need
 * to be synchronised across the table. E.g. setting the hover state across the same row in all columns
 * is not be viable with prop drilling, since reconciliation is too expensive.
 */
const useStyleMarshal = (scope: string) => {
	const styleEl = useRef<HTMLStyleElement>();

	useLayoutEffect(() => {
		if (typeof document !== 'undefined') {
			styleEl.current = document.createElement('style');
			styleEl.current.id = 'roadmap-data-styles';

			const head = document.querySelector('head');
			head && head.appendChild(styleEl.current);
		}

		return () => {
			styleEl.current?.parentNode?.removeChild(styleEl.current);
		};
	}, []);

	// The primitive utils to manipulate our custom style element
	const styleUtils = useMemo(() => {
		const insertStyle = (className: ClassName, rule: string) => {
			removeStyle(className);
			addToStylesheet(toSelector(className), rule);
		};

		const removeStyle = (className: ClassName) => {
			if (!styleEl.current || !styleEl.current.sheet) {
				return;
			}

			const ruleIndex = getIndexBySelector(
				scope,
				styleEl.current.sheet.cssRules,
				toSelector(className),
			);

			if (ruleIndex !== -1) {
				styleEl.current.sheet.deleteRule(ruleIndex);
			}
		};

		const updateStyle = (targetClassNames: ClassName[], style: string) => {
			if (!styleEl.current || !styleEl.current.sheet) {
				return;
			}

			const ruleIndex = getIndexByRule(styleEl.current.sheet.cssRules, style);
			const selectors = toSelectors(targetClassNames, scope);

			ruleIndex !== -1 && styleEl.current.sheet.deleteRule(ruleIndex);

			if (selectors.length > 0) {
				styleEl.current.sheet.insertRule(
					`${selectors.join(', ')} {${toRule(style)}}`,
					styleEl.current.sheet.cssRules.length,
				);
			}
		};

		const addToStylesheet = (selector: string, rule: string) => {
			styleEl.current?.sheet?.insertRule(
				`.${scope} ${String(selector)} {${rule.replace('\n', ' ')}}`,
				styleEl.current.sheet.cssRules.length,
			);
		};

		return { insertStyle, removeStyle, updateStyle };
	}, [scope]);

	// The view specific utils to manipulate our marshalled elements
	const styleAPI = useMemo(() => {
		const hoverRow = (id: string) => {
			styleUtils.insertStyle(classNames.row(id), styles.itemHover);
		};
		const leaveRow = (id: string) => {
			styleUtils.removeStyle(classNames.row(id));
		};

		const selectRow = (ids: string[]) => {
			const selectedClasses = ids.map((id) => classNames.row(id));
			styleUtils.updateStyle([], styles.itemHover);
			styleUtils.updateStyle(selectedClasses, styles.itemSelected);
		};
		const deselectRow = () => {
			styleUtils.updateStyle([], styles.itemSelected);
		};

		const dragRow = (id: string) => {
			styleUtils.removeStyle(classNames.inlineCreate);
			styleUtils.insertStyle(classNames.row(id), styles.itemDragging);
			styleUtils.insertStyle(classNames.rowChild(id), styles.itemDragging);
			styleUtils.insertStyle(classNames.itemsContainer, styles.itemsContainerDragging);
		};
		const dropRow = (id: string, wasSelected: boolean) => {
			styleUtils.removeStyle(classNames.createChild);
			styleUtils.removeStyle(classNames.row(id));
			styleUtils.removeStyle(classNames.rowChild(id));
			styleUtils.removeStyle(classNames.dragIndicator);
			styleUtils.removeStyle(classNames.itemsContainer);
			wasSelected && styleUtils.insertStyle(classNames.row(id), styles.itemSelected);
		};
		const showDragIndicator = (top: number, left?: number) => {
			styleUtils.insertStyle(classNames.dragIndicator, styles.getDragIndicator(top, left));
		};
		const dragOverParent = (parentId: string, isSelected: boolean) => {
			styleUtils.removeStyle(classNames.dragIndicator);
			const style = isSelected ? styles.selectedItemCanBeCombined : styles.itemCanBeCombined;
			styleUtils.updateStyle([classNames.row(parentId)], style);
		};
		const dragOutOfBounds = () => {
			styleUtils.removeStyle(classNames.dragIndicator);
		};

		const showInlineCreate = (top: number, tabFocused?: boolean) => {
			styleUtils.insertStyle(classNames.inlineCreate, styles.getInlineCreateButton(top));

			if (tabFocused) {
				styleUtils.insertStyle(classNames.inlineCreateIcon, styles.getInlineCreateIcon());
			}

			if (!tabFocused) {
				styleUtils.removeStyle(classNames.inlineCreateIcon);
			}
		};
		const hideInlineCreate = () => {
			styleUtils.removeStyle(classNames.inlineCreate);
			styleUtils.removeStyle(classNames.inlineCreateIcon);
		};
		const hideChildCreate = () => {
			styleUtils.insertStyle(classNames.createChild, styles.itemDragging);
		};

		const blockPointerEvents = () => {
			styleUtils.insertStyle(classNames.itemsContainer, styles.itemsContainerScrolling);
		};
		const allowPointerEvents = () => {
			styleAPI.removeStyle(classNames.itemsContainer);
		};

		return {
			...styleUtils,
			hoverRow,
			leaveRow,
			selectRow,
			deselectRow,
			dragRow,
			dropRow,
			showDragIndicator,
			dragOverParent,
			dragOutOfBounds,
			showInlineCreate,
			hideInlineCreate,
			hideChildCreate,
			blockPointerEvents,
			allowPointerEvents,
		};
	}, [styleUtils]);

	return styleAPI;
};

export { useStyleMarshal };

type StyleAPI = ReturnType<typeof useStyleMarshal>;
export type { StyleAPI };
