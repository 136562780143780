import type { Action } from 'react-sweet-state';

import type { State } from '../../common/types';

export type SetExtendedWidthAction = (extendedContentWidth: number) => Action<State>;

export const setExtendedContentWidth: SetExtendedWidthAction =
	(extendedContentWidth: number) =>
	({ setState }) => {
		setState({ extendedContentWidth });
	};
