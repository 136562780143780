/** @jsx jsx */
import React, { type Ref, type KeyboardEvent, type MouseEvent } from 'react';
import { css, jsx } from '@compiled/react';
import ColorPaletteMenu from '@atlaskit/color-picker/ColorPaletteMenu';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { Color } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import {
	getColorFromValue,
	getValueFromColor,
	getDefaultColorPalette,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { Title } from '../../../../common/ui/flyout/styled.tsx';

type Props = {
	isFocusLockEnabled?: boolean;
	initialFocusRef?: Ref<HTMLInputElement>;
	title?: string;
	color: Color;
	onUpdateColor: (event: KeyboardEvent | MouseEvent, color: Color) => void;
};

const ColorMenu = ({ isFocusLockEnabled, initialFocusRef, title, color, onUpdateColor }: Props) => {
	const { formatMessage } = useIntl();

	const onChangeColor = (event: KeyboardEvent | MouseEvent, value: string) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const newColor = value as Color;
		const safeColor = getColorFromValue(newColor);
		onUpdateColor?.(event, safeColor);
	};

	const colorPalette = getDefaultColorPalette(formatMessage);

	return (
		<div
			data-testid="roadmap.timeline-table-kit.ui.chart-timeline-overlay.context-menu.color"
			css={containerContentStyles}
		>
			{title ? <Title>{title}</Title> : null}
			<ColorPaletteMenu
				isFocusLockEnabled={isFocusLockEnabled}
				initialFocusRef={initialFocusRef}
				label={title}
				cols={colorPalette.length / 2}
				selectedColor={getValueFromColor(color)}
				palette={colorPalette}
				onChange={onChangeColor}
			/>
		</div>
	);
};

const containerContentStyles = css({
	maxWidth: '260px',
	backgroundColor: token('elevation.surface.overlay'),
	padding: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	margin: '1px', // NOTE: Leave space for focus ring from parent container, e.g. context menu
	overflow: 'hidden',
	boxShadow: token('elevation.shadow.overlay'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		boxShadow: 'none',
	},
});

export { ColorMenu };
