import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { SprintMenu as RoadmapSprintMenu } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/context-menu/sprint/index.tsx';
import type { Props } from './types.tsx';

const EditSprintMenu = ({
	isSprintClearable,
	sprints,
	setInitialFocusRef,
	setCloseMenu,
	onUpdate,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onUpdateSprint = useCallback(
		(event: MouseEvent | KeyboardEvent, sprintId: string | undefined) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'option',
			});

			const attributes = {
				type: sprintId !== undefined ? 'movedToSprint' : 'clearedSprint',
			};

			fireUIAnalytics(analyticsEvent, 'editSprintOption', attributes);
			if (!sprintId) {
				traceUFOPress('timeline-clear-sprint-option-clicked', event.timeStamp);
			} else {
				traceUFOPress('timeline-edit-sprint-option-clicked', event.timeStamp);
			}
			onUpdate(sprintId, analyticsEvent);
			setCloseMenu(event);
		},
		[createAnalyticsEvent, onUpdate, setCloseMenu],
	);

	return (
		<RoadmapSprintMenu
			showClearOption={isSprintClearable}
			showClearOptionSeparator
			sprints={sprints}
			initialFocusRef={setInitialFocusRef}
			onUpdateSprint={onUpdateSprint}
		/>
	);
};

export default EditSprintMenu;
