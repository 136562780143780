import React, { useCallback, useMemo, type KeyboardEvent, type MouseEvent } from 'react';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import RoadmapChangeParentMenu from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/change-parent-menu/index.tsx';
import type { Option } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/change-parent-menu/types.tsx';
import type { Props } from './types.tsx';

const ReparentMenuWrapper = ({
	issue,
	parentOptions,
	setParent,
	setInitialFocusRef: setFocusRef,
	setCloseMenu,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onOptionSelect = useCallback(
		(event: MouseEvent | KeyboardEvent, option: Option) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'option',
			});
			setParent(option.id, analyticsEvent);
			fireUIAnalytics(analyticsEvent, 'reparentMenuOption', {
				originalParentId: issue?.parentId.value,
				newParentId: option.id,
			});
			if (!fg('platform_button_item-add-ufo-metrics')) {
				traceUFOPress('timeline-reparent-option-clicked', event.timeStamp);
			}
			setCloseMenu(event);
		},
		[createAnalyticsEvent, issue?.parentId.value, setCloseMenu, setParent],
	);

	const filteredParentOptions = useMemo(
		() => parentOptions?.filter((option) => option.id !== issue?.parentId.value) ?? [],
		[issue?.parentId.value, parentOptions],
	);

	return (
		<RoadmapChangeParentMenu
			options={filteredParentOptions}
			onOptionSelect={onOptionSelect}
			inputRef={setFocusRef}
		/>
	);
};

export default ReparentMenuWrapper;
