// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi, Action } from '@atlassian/react-sweet-state';
import { INTERVAL } from '../../../../../common/constants/date.tsx';
import type { BarDragType } from '../../../../../common/types/chart-item.tsx';
import type { State, ContainerProps } from '../../common/types.tsx';
import { getPreviewBaselinePosition } from './utils.tsx';

export type OnDragStartAction = (id: string, type: BarDragType) => Action<State, ContainerProps>;

export const onDragStart =
	(id: string, dragType: BarDragType) =>
	(
		{ getState, setState }: StoreActionApi<State>,
		{ items, sideEffectMarshal, setViewportCache }: ContainerProps,
	) => {
		const activeItem = items.find((item) => item.id === id);

		if (activeItem) {
			const { itemPositions, hasLingered: hasLingeredFromState } = getState();
			const previewBaselinePosition = getPreviewBaselinePosition(activeItem, itemPositions);

			/* Reset hasLingered for non-INTERVAL type, we don't highlight target interval while dragging.
			 */
			const hasLingered =
				activeItem.startDateType === INTERVAL && activeItem.dueDateType === INTERVAL
					? hasLingeredFromState
					: false;

			setViewportCache();
			sideEffectMarshal.custom.onBarInteractionStart();

			setState({ activeItem, dragType, previewBaselinePosition, hasLingered });
		}
	};
