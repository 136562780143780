/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo } from 'react';

import { css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { zIndex } from '../../../common/constants';
import { useExtendedContentWidth } from '../../../common/context/extended-content';
import { useListWidth } from '../../../common/context/list';

// NOTE: The scrollable overlay for content will extend to be outside the content container when items are filtered
// The styling here covers the scrollable overlay (e.g. columns overlay, today marker, etc.) when it goes beneath the content parts (e.g. scrolling or virtualisation)
const containerStyles = css({
	position: 'sticky',
	flex: '0 0 auto',
	backgroundColor: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_COVER,
});

export type CoverProps = {
	/** Type of cover to render */
	type: 'list' | 'extendedContent';
	/** Test ID for testing */
	testId?: string;
};

/**
 * Cover provides a visual barrier over content areas to ensure that
 * scrollable overlays (like columns overlay, today marker) don't appear beneath the
 * content sections when scrolling or during virtualization.
 *
 * This component handles both list and extended content covers.
 */
const Cover = ({ type, testId }: CoverProps) => {
	const [listWidth] = useListWidth();
	const [extendedContentWidth = 0] = useExtendedContentWidth();

	// Configure width and position based on cover type
	const width = type === 'list' ? listWidth : extendedContentWidth;
	const left = type === 'list' ? 0 : listWidth;

	// Don't render if width is 0 (e.g., if ExtendedContentProvider is not available)
	if (type === 'extendedContent' && extendedContentWidth === 0) {
		return null;
	}

	return (
		<div
			data-testid={testId ?? `${type}-cover`}
			css={containerStyles}
			style={{
				width: `${width}px`,
				left: `${left}px`,
			}}
		/>
	);
};

export default memo<CoverProps>(Cover);
