// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { PURPLE } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { RoadmapChartItemContentLite } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content-lite/index.tsx';
import type { ChartData } from '../../../../state/selectors/table/chart-pure/common/types.tsx';
import { getChartDataHash } from '../../../../state/selectors/table/index.tsx';
import type { State } from '../../../../state/types.tsx';

type OwnProps = {
	id: string;
};

type StateProps = ComponentProps<typeof RoadmapChartItemContentLite>;

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const issueChartData: ChartData | undefined = getChartDataHash(state)[id];
		if (issueChartData === undefined) {
			return {
				startDate: undefined,
				dueDate: undefined,
				// it doesn't matter what level and color are because the component will return null if either dates are undefined
				level: 0,
				color: PURPLE,
			};
		}

		const { color, startDate, dueDate, placeholder, level } = issueChartData;

		return {
			color,
			startDate,
			dueDate,
			placeholder,
			level,
		};
	},
	null,
	(stateProps: StateProps) => stateProps,
)(RoadmapChartItemContentLite);
