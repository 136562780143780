import type {
	ChartItemContentProps,
	ChartItemContentState,
	RenderChartItemContent,
} from './types/chart-item-content';
import type { RenderChartOverlay } from './types/chart-overlay';
import type { RenderChartTimelineOverlay } from './types/chart-timeline-overlay';
import type { RenderExtendedContent } from './types/extended-content';
import type { RenderHeaderItem } from './types/header-item';
import type { RenderListEmptyContent } from './types/list-empty-content';
import type { ListItemContentProps, RenderListItemContent } from './types/list-item-content';
import type {
	ListItemCreateProps,
	ListItemCreateState,
	RenderListItemCreate,
} from './types/list-item-create';
import type {
	ListItemMenuOptionProps,
	ListItemModalProps,
	RenderListItemMenuOption,
	RenderListItemModal,
} from './types/list-item-menu-option';
import type { RenderListItemSecondaryContent } from './types/list-item-secondary-content';
import type { RenderProvider, RenderProviderProps } from './types/provider';

// A renderer is effectively a no-op until it gets overridden
const DEFAULT_RENDERER = () => null;

type DefaultRenderers = {
	renderListItemMenuOption: RenderListItemMenuOption;
	renderListItemModal: RenderListItemModal;
	renderListHeaderItem: RenderHeaderItem;
	renderListEmptyContent: RenderListEmptyContent;
	renderListItemContent: RenderListItemContent;
	renderListItemSecondaryContent: RenderListItemSecondaryContent;
	renderListItemCreate: RenderListItemCreate;
	renderExtendedContent?: RenderExtendedContent;
	renderExtendedContentHeader?: RenderExtendedContent;
	renderChartHeaderItem: RenderHeaderItem;
	renderChartOverlay: RenderChartOverlay;
	renderChartItemContent: RenderChartItemContent;
	renderChartTimelineOverlay: RenderChartTimelineOverlay;
	renderProvider: RenderProvider;
};

const defaultRenderers: DefaultRenderers = {
	renderListItemMenuOption: DEFAULT_RENDERER,
	renderListItemModal: DEFAULT_RENDERER,
	renderListHeaderItem: DEFAULT_RENDERER,
	renderListEmptyContent: DEFAULT_RENDERER,
	renderListItemContent: DEFAULT_RENDERER,
	renderListItemSecondaryContent: DEFAULT_RENDERER,
	renderListItemCreate: DEFAULT_RENDERER,
	renderChartHeaderItem: DEFAULT_RENDERER,
	renderChartOverlay: DEFAULT_RENDERER,
	renderChartItemContent: DEFAULT_RENDERER,
	renderChartTimelineOverlay: DEFAULT_RENDERER,
	renderProvider: ({ children }: RenderProviderProps) => children,
};

// Consumers only need to provide a subset of the renderers
type Renderers = Partial<DefaultRenderers>;

export { defaultRenderers };
export type {
	Renderers,
	RenderListItemMenuOption,
	RenderListItemModal,
	ListItemMenuOptionProps,
	ListItemModalProps,
	RenderHeaderItem,
	RenderListEmptyContent,
	RenderListItemContent,
	RenderListItemSecondaryContent,
	RenderListItemCreate,
	RenderExtendedContent,
	RenderChartOverlay,
	RenderChartTimelineOverlay,
	RenderChartItemContent,
	ListItemContentProps,
	ListItemCreateProps,
	ListItemCreateState,
	RenderProvider,
	RenderProviderProps,
	ChartItemContentProps,
	ChartItemContentState,
};
