import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	updateAndPersistIssue,
	type UpdateIssueAction,
} from '../../../../../../state/entities/issues/actions.tsx';
import type { DispatchProps, OwnProps } from './types.tsx';
import ChangeColorMenu from './view.tsx';

type Action = UpdateIssueAction;

const mapDispatchToProps = (dispatch: Dispatch<Action>, { id }: OwnProps): DispatchProps => ({
	onUpdate: (color, analyticsEvent) => {
		dispatch(updateAndPersistIssue(id, { color }, analyticsEvent));
	},
});

export default connect(null, mapDispatchToProps)(ChangeColorMenu);
