/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { memo, useCallback } from 'react';

import { css, jsx } from '@compiled/react';

import Resizer from '../../../../common/components/resizer';
import { getMaxExtendedContentWidth } from '../../../../common/components/resizer/max-width-utils';
import { useMaxWidth } from '../../../../common/components/resizer/use-max-width';
import {
	EXTENDED_CONTENT_RESIZE_DRAG_AND_DROP,
	MINIMUM_EXTENDED_CONTENT_WIDTH,
	zIndex,
} from '../../../../common/constants';
import { useExtendedContentWidth } from '../../../../common/context/extended-content';
import { useListWidth } from '../../../../common/context/list';
import type { OnSetExtendedContentWidth } from '../../../../common/types/callbacks';
import type { DndLongTaskMetricComponent } from '../../../../common/types/drag-and-drop';

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	bottom: 0,
	left: 0,
	width: '100%',
	pointerEvents: 'none',
});

const extendedContentResizerContainerStyles = css({
	position: 'sticky',
	// positioned after the list
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_RESIZER, // Reusing same z-index as list resizer
});

type Props = {
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	onSetExtendedContentWidth: OnSetExtendedContentWidth;
};

const ExtendedContentResizer = ({ DndLongTaskMetric, onSetExtendedContentWidth }: Props) => {
	const [listWidth] = useListWidth();
	const [extendedContentWidth, { setExtendedContentWidth }] = useExtendedContentWidth();
	const maxWidth = useMaxWidth(getMaxExtendedContentWidth);

	const onResize = useCallback(
		(width: number) => {
			setExtendedContentWidth(width);
		},
		[setExtendedContentWidth],
	);

	const onResizeEnd = useCallback(() => {
		onSetExtendedContentWidth(extendedContentWidth);
	}, [extendedContentWidth, onSetExtendedContentWidth]);

	return (
		<div css={containerStyles}>
			<div
				css={extendedContentResizerContainerStyles}
				style={{
					left: `${listWidth + extendedContentWidth}px`,
				}}
			>
				<Resizer
					width={extendedContentWidth}
					minWidth={MINIMUM_EXTENDED_CONTENT_WIDTH}
					maxWidth={maxWidth}
					DndLongTaskMetric={DndLongTaskMetric}
					metricType={EXTENDED_CONTENT_RESIZE_DRAG_AND_DROP}
					analyticsSubject="extendedContent"
					analyticsSubjectId="resizedExtendedContent"
					onResize={onResize}
					onResizeEnd={onResizeEnd}
				/>
			</div>
		</div>
	);
};

export default memo<Props>(ExtendedContentResizer);
