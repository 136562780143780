import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { RoadmapDateLabels } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/ui/date-labels/index.tsx';
import { RoadmapBarContent } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/bar-content/index.tsx';
import type {
	BarContentProps,
	BarContentState,
	BarIconRendererProps,
	BarDependencyHandlerRendererProps,
	BarDependencyHandlerRendererState,
	BarDateLabelRendererProps,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import BarIcon from './bar-icon/index.tsx';
import DependencyHandler from './dependency-handler/index.tsx';

const renderBarContent = (barContentProps: BarContentProps, barContentState: BarContentState) => {
	const barContentRenderers = {
		renderBarIcon: ({ id, isColorInverted }: BarIconRendererProps) => {
			return (
				<BarIcon
					id={id}
					isColorInverted={isColorInverted}
					{...(isVisualRefreshEnabled() && fg('visual-refresh_drop_6')
						? { isHovered: barContentState.isHovered }
						: {})}
				/>
			);
		},
		renderDependencyHandler: (
			props: BarDependencyHandlerRendererProps,
			state: BarDependencyHandlerRendererState,
		) => <DependencyHandler {...props} {...state} />,
		renderDateLabel: (props: BarDateLabelRendererProps) => <RoadmapDateLabels {...props} />,
	} as const;

	return <RoadmapBarContent {...barContentProps} {...barContentState} {...barContentRenderers} />;
};

export { renderBarContent };
