/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag Fragment
 */
import { Fragment, memo } from 'react';

import { css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { DEFAULT_HEADER_ROW_ID, LIST_COLUMN_COORDINATE } from '../../../common/constants';
import {
	useExtendedContentLayout,
	useExtendedContentWidth,
} from '../../../common/context/extended-content';
import { useListWidth } from '../../../common/context/list';
import { useGridCell } from '../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';
import { type ExtendedContentItems } from '../../../common/types/extended-content';
import type { RenderExtendedContent } from '../../../renderers';

import { ExtendedContentDivider } from './divider';

const rowHeaderCellStyles = css({
	position: 'sticky',
	flex: '0 0 auto',
	left: 0,
	padding: 0,
	overflow: 'hidden',
	backgroundColor: token('elevation.surface'),
	zIndex: 12,
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused')}`,
	},
});

const defaultRowHeaderContainerStyles = css({
	boxSizing: 'border-box',
	position: 'absolute',
	width: '100%',
	height: '100%',
	backgroundColor: token('color.background.neutral'),
	borderTop: `1px solid ${token('color.border')}`,
	borderBottom: `1px solid ${token('color.border')}`,
});

const subRowHeaderContainerStyles = css({
	boxSizing: 'border-box',
	position: 'absolute',
	width: '100%',
	height: '100%',
	display: 'flex',
	flex: '0 0 auto',
	alignItems: 'center',
	borderBottom: `1px solid ${token('color.border')}`,
});

const headerGridCellStyles = css({
	position: 'absolute',
	height: '100%',
});

export type ExtendedContentHeaderProps = {
	id: string;
	extendedContent: ExtendedContentItems;
	itemHeight: number;
	renderExtendedContentHeader: RenderExtendedContent;
};

/**
 * This component is used to render the extended content headers. It will render
 * a full-width header for the default (top) row and sub-headers for each column.
 *
 * - Is sticky positioned to the right of ListHeaderCell based on the listWidth
 * - Has a width determined by the extendedContentWidth global state
 * - Wraps each header cell in appropriate <div role="columnheader" > element
 * - @TODO: Wraps the default header in (???) role
 * - Ensures horizontal overflow is hidden
 * - Allows ChartHeaderCell content to "slide" under it (based on z-index)
 * - Renders a full-width header for the default (top) row
 * - Renders individual column headers for sub-header rows
 */
const ExtendedContentHeader = ({
	extendedContent,
	id,
	itemHeight,
	renderExtendedContentHeader,
}: ExtendedContentHeaderProps) => {
	const [listWidth] = useListWidth();
	const [extendedContentWidth] = useExtendedContentWidth();
	const [extContentLayout] = useExtendedContentLayout();

	const isDefaultHeader = id === DEFAULT_HEADER_ROW_ID;
	const cellRef = useGridCell(id, LIST_COLUMN_COORDINATE, isDefaultHeader);

	return (
		<div
			css={rowHeaderCellStyles}
			style={{
				left: `${listWidth}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
				width: `${extendedContentWidth}px`,
				height: `${itemHeight}px`,
			}}
		>
			{isDefaultHeader ? (
				// For the default header (fields header), render a single full-width cell
				<div
					ref={cellRef}
					css={headerGridCellStyles}
					style={{
						width: `${extendedContentWidth}px`,
					}}
				>
					<div css={defaultRowHeaderContainerStyles}>
						{renderExtendedContentHeader({ id, key: 'default-header' })}
					</div>
				</div>
			) : (
				<Fragment>
					{/* For sub-headers, map through the columns */}
					{extendedContent.map(({ key }, index) => (
						<div
							key={key}
							ref={cellRef}
							css={headerGridCellStyles}
							style={{
								left: `${extContentLayout[index].left}px`,
								width: `${extContentLayout[index].width}px`,
							}}
							role="columnheader"
						>
							<div css={subRowHeaderContainerStyles}>
								{renderExtendedContentHeader({ id, key })}
							</div>
						</div>
					))}

					{/* Render dividers between columns */}
					{extendedContent.map((_, index) => {
						// Skip the last column as it doesn't need a divider to its right
						if (index === extendedContent.length - 1) {
							return null;
						}

						const columnRight = extContentLayout[index].left + extContentLayout[index].width;

						// Divider is positioned at the right edge of each column
						return (
							<ExtendedContentDivider
								key={`divider-${index}`}
								left={columnRight}
								height={itemHeight}
								// Only show divider when it's within the visible area (not past the right edge)
								isVisible={columnRight <= extendedContentWidth}
							/>
						);
					})}
				</Fragment>
			)}
		</div>
	);
};

export default memo<ExtendedContentHeaderProps>(ExtendedContentHeader);
