import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';

import { actions, type Actions } from './actions';
import type { Props, State } from './common/types';
import {
	getExtendedContentColumnLayout,
	getExtendedContentMaxWidth,
	getExtendedContentWidth,
} from './selectors';
import { getExtendedContentLayout, getTotalExtendedContentWidth } from './utils';

const getExtendedContentInitialWidth = getTotalExtendedContentWidth;

const initialState: State = {
	extendedContentWidth: 0,
	extendedContentMaxWidth: 0,
	columnLayout: [],
};

export const extendedContentStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.extended-content',
});

export const useExtendedContentActions = createActionsHook(extendedContentStore);

export const useExtendedContentWidth = createHook(extendedContentStore, {
	selector: getExtendedContentWidth,
});

export const useExtendedContentMaxWidth = createHook(extendedContentStore, {
	selector: getExtendedContentMaxWidth,
});

export const useExtendedContentLayout = createHook(extendedContentStore, {
	selector: getExtendedContentColumnLayout,
});

export const useExtendedContentState = createHook(extendedContentStore);

export const ExtendedContentProvider = createContainer<State, Actions, Props>(
	extendedContentStore,
	{
		onInit:
			() =>
			({ setState }, { extendedContent }: Props) => {
				setState({
					extendedContentWidth: getExtendedContentInitialWidth(extendedContent ?? []),
					extendedContentMaxWidth: getTotalExtendedContentWidth(extendedContent ?? []),
					columnLayout: getExtendedContentLayout(extendedContent ?? []),
				});
			},
	},
);

export default ExtendedContentProvider;
