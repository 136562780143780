import React, { useMemo, useRef, type ReactNode } from 'react';
import { jpdRoadmapOnTimelineSpotlightEntryPoint } from '@atlassian/jira-roadmap-on-timeline/spotlight/entrypoint.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = { moreSettingsButton: ReactNode };

export const JpdRoadmapExperimentSpotlight = ({ moreSettingsButton }: Props) => {
	if (!fg('grw-880_jpd_roadmap_on_jira_timeline_killswitch')) {
		return moreSettingsButton;
	}

	return <JpdRoadmapExperimentSpotlightInternal moreSettingsButton={moreSettingsButton} />;
};

const JpdRoadmapExperimentSpotlightInternal = ({ moreSettingsButton }: Props) => {
	const hasLoadedEntrypoint = useRef(false);
	const runtimeProps = useMemo(() => ({ moreSettingsButton }), [moreSettingsButton]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		jpdRoadmapOnTimelineSpotlightEntryPoint,
		{},
	);

	if (!hasLoadedEntrypoint.current) {
		entryPointActions.load();
		hasLoadedEntrypoint.current = true;
	}

	return (
		<JiraEntryPointContainer
			id="growth.jpdRoadmapOnTimelineSpotlight"
			packageName="jpd-roadmap-on-timeline-spotlight"
			teamName="galileo-growth"
			runtimeProps={runtimeProps}
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={moreSettingsButton}
		/>
	);
};
