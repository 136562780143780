/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { css, jsx } from '@compiled/react';
import invariant from 'tiny-invariant';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	draggable,
	type ElementEventBasePayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import { token } from '@atlaskit/tokens';

import { zIndex } from '../../constants';
import type { DndLongTaskMetricComponent } from '../../types/drag-and-drop';
import type { TimelineTableInteractionMetricType } from '../../types/interaction-metrics';
import { fireUIAnalyticsEvent } from '../../utils/analytics';

export type ResizerProps = {
	/** Current width of the section being resized */
	width: number;
	/** Minimum allowed width for the section */
	minWidth: number;
	/** Maximum allowed width for the section */
	maxWidth: number;
	/** Component for metrics tracking */
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	/** Metric type identifier for analytics */
	metricType: TimelineTableInteractionMetricType;
	/** Analytics identifier for the section being resized */
	analyticsSubject: string;
	/** Analytics subject ID for the section being resized */
	analyticsSubjectId: string;
	/** Callback to update width during resize */
	onResize: (width: number) => void;
	/** Callback when resize is complete */
	onResizeEnd: (width: number) => void;
};

/**
 * A generic resizer component that handles drag-and-drop behavior for resizing sections.
 * This component is designed to be used by both the list resizer and extended content resizer.
 */
const Resizer = ({
	width,
	minWidth,
	maxWidth,
	DndLongTaskMetric,
	metricType,
	analyticsSubject,
	analyticsSubjectId,
	onResize,
	onResizeEnd,
}: ResizerProps) => {
	const [isDragging, setIsDragging] = useState(false);
	const dividerRef = useRef<HTMLDivElement | null>(null);
	const initialWidth = useRef<number>(width);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getProposedWidth = useCallback(
		({ location }: { location: DragLocationHistory }): number => {
			const diffX = location.current.input.clientX - location.initial.input.clientX;
			const proposedWidth = initialWidth.current + diffX;

			// Ensure width stays within min and max bounds
			return Math.min(Math.max(minWidth, proposedWidth), maxWidth);
		},
		[minWidth, maxWidth],
	);

	const onDragStart = useCallback(() => {
		initialWidth.current = width;
		setIsDragging(true);
	}, [width]);

	const onDrag = useCallback(
		({ location }: ElementEventBasePayload) => {
			onResize(getProposedWidth({ location }));
		},
		[getProposedWidth, onResize],
	);

	const onDrop = useCallback(
		({ location }: ElementEventBasePayload) => {
			preventUnhandled.stop();
			setIsDragging(false);

			const analyticsEvent = createAnalyticsEvent({
				action: 'resized',
				actionSubject: analyticsSubject,
				actionSubjectId: analyticsSubjectId,
				attributes: {
					didExpand: location.current.input.clientX > location.initial.input.clientX,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);

			onResizeEnd(initialWidth.current);
		},
		[createAnalyticsEvent, onResizeEnd, analyticsSubject, analyticsSubjectId],
	);

	// Ensure width doesn't exceed max constraint
	useEffect(() => {
		if (width > maxWidth) {
			onResize(maxWidth);
		}
	}, [width, maxWidth, onResize]);

	useEffect(() => {
		const divider = dividerRef.current;
		invariant(divider);

		return draggable({
			element: divider,
			onGenerateDragPreview: ({ nativeSetDragImage }) => {
				disableNativeDragPreview({ nativeSetDragImage });
				preventUnhandled.start();
			},
			onDragStart,
			onDrag,
			onDrop,
		});
	}, [onDragStart, onDrag, onDrop]);

	return (
		<>
			<div
				css={[resizeHandlerStyles, isDragging ? draggingStyles : staticStyles]}
				ref={dividerRef}
			/>
			{DndLongTaskMetric !== undefined && (
				<DndLongTaskMetric isDragging={isDragging} metricType={metricType} />
			)}
		</>
	);
};

const resizeHandlerStyles = css({
	position: 'absolute',
	top: 0,
	right: token('space.negative.025', '-2px'),
	width: token('space.100', '8px'),
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.LIST_RESIZER,
	transition: 'opacity 0.1s ease',
	pointerEvents: 'all',
	cursor: 'ew-resize',
	'&::after': {
		position: 'absolute',
		display: 'block',
		content: '',
		top: 0,
		right: token('space.025', '2px'),
		width: token('space.025', '2px'),
		height: '100%',
		backgroundColor: token('color.border.selected'),
		pointerEvents: 'none',
	},
});

const staticStyles = css({
	opacity: 0,
	'&:hover': {
		opacity: 1,
	},
});

const draggingStyles = css({
	opacity: 1,
});

export default Resizer;
