import type { ReactNode } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { State } from '../../../../state/types.tsx';

import { JpdRoadmapExperimentSpotlight } from './view.tsx';

const mapStateToProps = (_: State, { moreSettingsButton }: { moreSettingsButton: ReactNode }) => ({
	moreSettingsButton,
});

export default connect(mapStateToProps, {})(JpdRoadmapExperimentSpotlight);
