import { MINIMUM_EXTENDED_CONTENT_WIDTH, MINIMUM_LIST_WIDTH } from '../../constants';

// Maximum portion of the viewport width that the list can occupy
const LIST_MAX_WIDTH_RATIO = 0.9;

// Maximum portion of the viewport width that the extended content can occupy
const EXTENDED_CONTENT_MAX_WIDTH_RATIO = 0.6;

/**
 * Calculates the maximum allowed width for the list section.
 *
 * @param viewportWidth The current viewport width
 * @returns The maximum width the list can occupy
 */
export const getMaxListWidth = (viewportWidth: number): number => {
	const tableLimitedWidth = Math.round(viewportWidth * LIST_MAX_WIDTH_RATIO);
	return Math.max(MINIMUM_LIST_WIDTH(), tableLimitedWidth);
};

/**
 * Calculates the maximum allowed width for the extended content section.
 *
 * @param viewportWidth The current viewport width
 * @returns The maximum width the extended content can occupy
 */
export const getMaxExtendedContentWidth = (viewportWidth: number): number => {
	const tableLimitedWidth = Math.round(viewportWidth * EXTENDED_CONTENT_MAX_WIDTH_RATIO);
	return Math.max(MINIMUM_EXTENDED_CONTENT_WIDTH, tableLimitedWidth);
};
