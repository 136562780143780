import { type ExtendedContentItems } from '../../types/extended-content';

import type { ColumnLayout } from './common/types';

/**
 * Calculates the layout positions for each column in the extended content area.
 * For each column, calculates its left position (based on previous columns),
 * right position (left + width), and maintains its width.
 *
 * The layout ensures columns are positioned sequentially with no gaps,
 * handling special cases like zero-width columns.
 *
 * @example
 * ```tsx
 * const columns = [
 *   { key: 'status', width: 120 },
 *   { key: 'assignee', width: 180 }
 * ];
 * const layout = getExtendedContentLayout(columns);
 * // Returns:
 * // [
 * //   { left: 0, right: 120, width: 120 },
 * //   { left: 120, right: 300, width: 180 }
 * // ]
 * ```
 */
export const getExtendedContentLayout = (
	extendedContent: ExtendedContentItems,
): readonly ColumnLayout[] =>
	extendedContent.reduce<ColumnLayout[]>((acc, { initialWidth }, index) => {
		const { initialWidth: prevWidth } = extendedContent[index - 1] ?? [];
		const left = index === 0 ? 0 : acc[index - 1].left + prevWidth;
		const right = left + initialWidth;

		return acc.concat({ left, right, width: initialWidth });
	}, []);

/**
 * Calculates the total width of all columns in the extended content area.
 * This is used to determine the maximum width of the extended content section.
 *
 * @example
 * ```tsx
 * const columns = [
 *   { key: 'status', initialWidth: 120 },
 *   { key: 'assignee', initialWidth: 180 }
 * ];
 * const totalWidth = getTotalExtendedContentWidth(columns);
 * // Returns: 300
 * ```
 */
export const getTotalExtendedContentWidth = (extendedContent: ExtendedContentItems): number =>
	extendedContent.reduce((sum, { initialWidth }) => sum + initialWidth, 0);
