import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const MARKER_WIDTH = gridSize * 2.5;
export const MARKER_WIDTH_WITH_SPACE = MARKER_WIDTH + gridSize * 2;
export const MARKER_BORDER_WIDTH = 2;

export const COMPLETED = 'COMPLETED' as const;
export const IN_PROGRESS = 'IN_PROGRESS' as const;
export const WARNING = 'WARNING' as const;

export const MARKER_COLORS = {
	[COMPLETED]: token('color.icon.success'),
	[IN_PROGRESS]: token('color.icon.information'),
	[WARNING]: token('color.icon.danger'),
} as const;

export const STATUS_PRIORITY = {
	[COMPLETED]: 0,
	[IN_PROGRESS]: 1,
	[WARNING]: 2,
} as const;
