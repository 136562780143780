/** @jsx jsx */
import React, {
	useMemo,
	forwardRef,
	useCallback,
	type MouseEvent,
	type KeyboardEvent,
	type ForwardedRef,
} from 'react';
import { css, jsx } from '@compiled/react';
import { mergeRefs } from 'use-callback-ref';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { isEnterOrSpaceKey } from '@atlassian/timeline-table/common/utils/events';
import { CHART_BAR_CONTENT_Z_INDEX } from '../../../../../../common/constants/z-index.tsx';
import type { Position } from '../../../../../../common/types/common.tsx';
import messages from './messages.tsx';

type Props = {
	level: number;
	isColorInverted?: boolean;
	isHovered?: boolean;
	onClick: (position: Position) => void;
};

type HandleEventArgs = {
	timeStamp: number;
	target: HTMLElement;
};

const RoadmapBarIcon = forwardRef(
	({ level, isColorInverted, isHovered, onClick }: Props, ref: ForwardedRef<HTMLDivElement>) => {
		const { formatMessage } = useIntl();

		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleEvent = useCallback(
			({ timeStamp, target }: HandleEventArgs) => {
				traceUFOPress('timeline-dependency-clicked', timeStamp);

				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'icon',
				});
				fireUIAnalytics(analyticsEvent, 'dependencyIcon', { level });

				const { top, left, width } = target.getBoundingClientRect();
				const position = { y: top + width, x: left };

				onClick(position);
			},
			[onClick, level, createAnalyticsEvent],
		);

		const onClickCallback = useCallback(
			(event: MouseEvent<HTMLElement>) => {
				handleEvent({
					timeStamp: event.timeStamp,
					target: event.currentTarget,
				});
			},
			[handleEvent],
		);

		const onKeyDownCallback = useCallback(
			(event: KeyboardEvent<HTMLDivElement>) => {
				if (isEnterOrSpaceKey(event)) {
					event.preventDefault();
					handleEvent({
						timeStamp: event.timeStamp,
						target: event.currentTarget,
					});
				}
			},
			[handleEvent],
		);

		const CustomToolTipTag = useMemo(
			() =>
				// FIXME: Chronos should fix this by extracting the forwarded component

				forwardRef<HTMLElement>((props, tooltipRef) => (
					<div
						css={[
							// eslint-disable-next-line no-nested-ternary
							isVisualRefreshEnabled()
								? fg('visual-refresh_drop_6')
									? iconWrapperStyles
									: iconWrapperStylesVisuallyRefreshedOld
								: iconWrapperStylesOld,
							isHovered &&
								isVisualRefreshEnabled() &&
								fg('visual-refresh_drop_6') &&
								iconHoveredStyles,
							isColorInverted && iconInvertedColorStyles,
						]}
						{...props}
						ref={
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							mergeRefs([ref, tooltipRef as unknown as React.ForwardedRef<HTMLDivElement>])
						}
						onClick={onClickCallback}
						role="button"
						onKeyDown={onKeyDownCallback}
						tabIndex={0}
						aria-label={formatMessage(messages.viewDetail)}
					/>
				)),
			[isHovered, isColorInverted, ref, onClickCallback, onKeyDownCallback, formatMessage],
		);

		return (
			<Tooltip tag={CustomToolTipTag} position="top" content={formatMessage(messages.viewDetail)}>
				<LinkIcon
					label=""
					color={isColorInverted ? token('color.icon') : token('color.text.inverse')}
					testId="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.bar-icon.link-icon"
					LEGACY_size="small"
				/>
			</Tooltip>
		);
	},
);

export { RoadmapBarIcon };

// Child selector is for the nested tooltip wrapper
const iconWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: token('space.250'),
	height: token('space.250'),
	borderRadius: '3px',
	overlay: 'shadow',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_BAR_CONTENT_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.inverse.subtle.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});

// Child selector is for the nested tooltip wrapper
const iconWrapperStylesVisuallyRefreshedOld = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: token('space.250'),
	height: token('space.250'),
	marginRight: token('space.050'),
	borderRadius: '3px',
	overlay: 'shadow',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_BAR_CONTENT_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.inverse.subtle.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});

// Child selector is for the nested tooltip wrapper
const iconWrapperStylesOld = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: token('space.250'),
	height: token('space.250'),
	marginRight: token('space.050'),
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_BAR_CONTENT_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.inverse.subtle.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
	},
});

const iconInvertedColorStyles = css({
	'&:hover': {
		backgroundColor: token('color.background.neutral.hovered'),
	},
});

const iconHoveredStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '10px',
});
