import type { Dispatch } from 'redux';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { SPRINT_IDS } from '@atlassian/jira-software-roadmap-model/src/issue-view/index.tsx';
import {
	updateAndPersistIssue,
	type UpdateIssueAction,
} from '../../../../../../state/entities/issues/actions.tsx';
import type { DispatchProps, OwnProps } from './types.tsx';
import EditSprintMenu from './view.tsx';

type Action = UpdateIssueAction;

const mapDispatchToProps = (dispatch: Dispatch<Action>, { id }: OwnProps): DispatchProps => ({
	onUpdate: (sprintId, analyticsEvent) => {
		dispatch(
			updateAndPersistIssue(
				id,
				sprintId !== undefined ? { sprintId } : { clearFields: [SPRINT_IDS] },
				analyticsEvent,
			),
		);
	},
});

export default connect(null, mapDispatchToProps)(EditSprintMenu);
