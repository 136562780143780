/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

const iconWrapperStyles = css({
	display: 'flex',
	width: '20px',
	height: '20px',
	borderRadius: '50%',
	alignItems: 'center',
	justifyContent: 'center',
	boxShadow: token('elevation.shadow.raised'),
});

const iconWrapperExtraBorderRadius = css({
	borderRadius: '3px',
});

type Props = {
	color: string;
	isColorInverted?: boolean;
};

const DependencyDragHandler = ({ color, isColorInverted }: Props) => (
	<div
		css={[iconWrapperStyles, isVisualRefreshEnabled() && iconWrapperExtraBorderRadius]}
		style={{ backgroundColor: color }}
	>
		<LinkIcon
			LEGACY_size="small"
			label=""
			color={isColorInverted ? token('color.icon') : token('color.icon.inverse')}
		/>
	</div>
);

export { DependencyDragHandler };
