import React, { useCallback, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import TodayButton from '@atlassian/jira-aais-timeline-toolbar/src/ui/today-button/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { timelineScroller } from '@atlassian/jira-software-roadmap-utils/src/utils/timeline-scroller.tsx';
import { useTimelineState } from '@atlassian/timeline-table/common/context/timeline';
import { fg } from '@atlassian/jira-feature-gating';
import { useFireJpdRoadmapOnTimelineSpotlightTrigger } from '@atlassian/jira-roadmap-on-timeline-utils/src/utils.tsx';

const RoadmapTodayButton = ({ isHidden }: { isHidden?: boolean }) => {
	const [{ timelineOriginPosition }] = useTimelineState();
	const fireJpdRoadmapOnTimelineSpotlightTrigger = useFireJpdRoadmapOnTimelineSpotlightTrigger(); // GALILEO-1454

	const onClickCallback = useCallback(
		(_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'todayButton');
			timelineScroller.scrollTo({ left: timelineOriginPosition });
			// GALILEO-1454 START
			if (fg('grw-880_jpd_roadmap_on_jira_timeline_killswitch')) {
				fireJpdRoadmapOnTimelineSpotlightTrigger();
			}
			// GALILEO-1454 END
		},
		[timelineOriginPosition, fireJpdRoadmapOnTimelineSpotlightTrigger],
	);

	return (
		<TodayButton
			onClick={onClickCallback}
			{...(fg('jsw_roadmaps_timeline-fix-a11y-rain') ? { isHidden } : {})}
		/>
	);
};

export { RoadmapTodayButton };
