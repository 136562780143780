/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type KeyboardEvent, memo, type MouseEvent, useCallback } from 'react';

import { css, jsx } from '@compiled/react';
import { mergeRefs } from 'use-callback-ref';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { B100, B400, B50, N40, N50, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { INSIDE } from '../../../../../common/constants';
import { useCreateTrigger } from '../../../../../common/context/side-effect-marshal/focus-marshal/use-create-trigger';
import { getCreateChildButtonClassName } from '../../../../../common/context/side-effect-marshal/style-marshal/utils';
import type { OnCreateClick } from '../../../../../common/types/callbacks';
import type { ItemId } from '../../../../../common/types/item';
import { fireUIAnalyticsEvent } from '../../../../../common/utils/analytics';

type Props = {
	parentId: ItemId;
	label: string;
	onInlineCreateClicked: OnCreateClick;
	isVisible?: boolean;
};

const CreateChildButton = ({ parentId, label, onInlineCreateClicked, isVisible }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { createTriggerRef, onCreateTriggerClick } = useCreateTrigger(parentId, 'CHILD');

	const onClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			// Do not consider clicking this as also clicking on the list item itself
			event.stopPropagation();
			onCreateTriggerClick();
			traceUFOPress('timeline-add-item-clicked', event.timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'childIssueCreateButton',
				attributes: {
					position: INSIDE,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);

			onInlineCreateClicked({ parentId, position: INSIDE });
		},
		[onCreateTriggerClick, createAnalyticsEvent, onInlineCreateClicked, parentId],
	);

	const stopPropagation = useCallback((e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			e.stopPropagation();
		}
	}, []);

	return fg('platform-component-visual-refresh') ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
		<div className={getCreateChildButtonClassName().className}>
			<Box xcss={!isVisible && hideButtonStyles}>
				<IconButton
					ref={createTriggerRef}
					icon={AddIcon}
					testId={`roadmap.timeline-table.components.list-item.create-child-button.container-${parentId}`}
					label={label}
					onClick={onClick}
					onKeyUp={stopPropagation}
					appearance="subtle"
					spacing="compact"
					isTooltipDisabled={false}
				/>
			</Box>
		</div>
	) : (
		<Tooltip content={label}>
			{({ ref: tooltipRef, ...tooltipProps }) => (
				// eslint-disable-next-line @atlaskit/design-system/no-html-button
				<button
					{...tooltipProps}
					ref={mergeRefs([tooltipRef, createTriggerRef])}
					css={[buttonStyles, !isVisible && hideButtonStylesOld]}
					data-testid={`roadmap.timeline-table.components.list-item.create-child-button.container-${parentId}`}
					aria-label={label}
					onClick={onClick}
					onKeyUp={stopPropagation}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={getCreateChildButtonClassName().className}
				>
					<EditorAddIcon label="" LEGACY_size="medium" spacing="spacious" />
				</button>
			)}
		</Tooltip>
	);
};

export default memo<Props>(CreateChildButton);

const hideButtonStyles = xcss({
	opacity: 0,
	pointerEvents: 'none',
});

const buttonStyles = css({
	padding: 0,
	margin: 0,
	border: 'none',
	cursor: 'pointer',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	borderRadius: token('border.radius', '3px'),
	verticalAlign: 'top',
	backgroundColor: token('color.background.neutral', N40),
	color: token('color.text.subtle', N500),
	outline: 'none',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
	'&:hover, &:focus': {
		backgroundColor: token('color.background.neutral.hovered', N50),
	},
	'&:active': {
		backgroundColor: token('color.background.selected.pressed', B50),
		color: token('color.icon.selected', B400),
	},
});

const hideButtonStylesOld = css({
	opacity: 0,
	pointerEvents: 'none',
});
