import React, { forwardRef, useMemo, useCallback, type MouseEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import HighlightText from '@atlassian/jira-software-highlight-text/src/ui/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	SummaryContainer as Container,
	SummaryIcon,
	SummaryTitle,
	SummaryKeyLink,
} from '../../../common/ui/summary/index.tsx';
import messages from './messages.tsx';
import ProgressIndicator, { type ProgressMetadata } from './progress-indicator/index.tsx';

type Props = {
	isDone: boolean;
	title: string;
	iconUrl: string;
	itemKey?: string;
	iconAltText: string;
	highlightText: string;
	progressMetadata: ProgressMetadata;
	isActive: boolean;
	// clean up chronos_a11y_fixes_jtran2 remove optional type for id
	id?: IssueId;
};

const TooltipTag = forwardRef<HTMLElement>((props, ref) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box xcss={tooltipTag} ref={ref} {...props} />
));

const Summary = ({
	title,
	iconUrl,
	isDone,
	itemKey,
	iconAltText,
	highlightText,
	isActive,
	progressMetadata,
	id,
}: Props) => {
	const highlight = useMemo(() => [highlightText], [highlightText]);
	const { formatMessage } = useIntl();

	const onClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
		const withCmd = event.ctrlKey || event.metaKey;
		// We do not want to navigate away when clicking unless there is explicit intent
		// In most cases, a click should open up the item details
		withCmd ? event.stopPropagation() : event.preventDefault();
	}, []);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLAnchorElement>) => {
			if (event.key !== 'Enter') {
				return;
			}

			event.stopPropagation();

			const link = `/browse/${itemKey}`;
			const withCmd = event.ctrlKey || event.metaKey;

			if (withCmd) {
				event.preventDefault();

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(link, '_blank');
			}
		},
		[itemKey],
	);

	const itemKeyEl =
		highlightText !== '' && itemKey !== undefined ? (
			<HighlightText
				data-testid="roadmap.timeline-table-kit.ui.list-item-content.summary.item-el"
				text={itemKey}
				highlight={highlight}
			/>
		) : (
			itemKey
		);

	const titleEl =
		highlightText !== '' ? (
			<HighlightText
				data-testid="roadmap.timeline-table-kit.ui.list-item-content.summary.title-el"
				text={title}
				highlight={highlight}
			/>
		) : (
			title
		);

	const renderSummaryTitle = () => (
		<SummaryTitle
			type="p"
			testId="roadmap.timeline-table-kit.ui.list-item-content.summary.title"
			{...(id ? { id } : {})}
		>
			{titleEl}
		</SummaryTitle>
	);

	return (
		<>
			<Container>
				<SummaryIcon src={iconUrl} alt={iconAltText} draggable={false} />
				{itemKey && (
					<SummaryKeyLink
						href={`/browse/${itemKey}`}
						isDone={isDone}
						testId="roadmap.timeline-table-kit.ui.list-item-content.summary.key"
						aria-label={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.issueKeyLabelIssueTermRefresh
								: messages.issueKeyLabel,
							{ itemKey },
						)}
						onClick={onClick}
						onKeyDown={onKeyDown}
						id={id ? `timeline-summary-key-${id}` : ''}
						draggable={false}
					>
						{itemKeyEl}
					</SummaryKeyLink>
				)}
				{title.length > 16 ? (
					<Tooltip position="bottom-start" content={titleEl} tag={TooltipTag}>
						{renderSummaryTitle()}
					</Tooltip>
				) : (
					renderSummaryTitle()
				)}
			</Container>
			{progressMetadata.total > 0 && (
				<ProgressIndicator {...progressMetadata} isActive={isActive} />
			)}
		</>
	);
};

const tooltipTag = xcss({
	overflow: 'hidden',
});

const DEFAULT_PROGRESS_META = {
	completed: 0,
	total: 0,
};

Summary.defaultProps = {
	isDone: false,
	iconAltText: '',
	highlightText: '',
	progressMetadata: DEFAULT_PROGRESS_META,
};

export default Summary;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ProgressMetadata };
