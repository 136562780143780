/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, { memo } from 'react';

import { css, jsx } from '@compiled/react';

import { fg } from '@atlaskit/platform-feature-flags';
import { N0, N20, N40A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { DEFAULT_HEADER_ROW_ID, DEFAULT_SUBHEADER_HEIGHT, zIndex } from '../../common/constants';
import { useHeaderState } from '../../common/context/header';
import { isScrollToTop } from '../../common/context/scroll-meta/main';
import { type ExtendedContentItems } from '../../common/types/extended-content';
import { type HeaderItem } from '../../common/types/header';
import type { RenderExtendedContent, RenderHeaderItem } from '../../renderers';
import ExtendedContentHeader from '../content/extended-content/header';
import ScrollableOverlay from '../scrollable-overlay';

import { ChartHeaderCell } from './chart';
import ListHeaderCell from './list';

const headerContainerStyles = css({
	position: 'sticky',
	boxSizing: 'border-box',
	width: '100%',
	top: 0,
	backgroundColor: token('elevation.surface', N0),
	borderRadius: `${token('border.radius', '3px')} 0 0 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	zIndex: zIndex.ROW_HEADER,
});

const headerRowStyles = css({
	position: 'relative',
	display: 'flex',
	marginTop: token('space.0'),
});

const headerRowStylesOld = css({
	position: 'relative',
	display: 'flex',
});

const headerShadow = css({
	boxShadow: `${token(
		'elevation.shadow.overflow',
		`0 ${token('space.050', '4px')} ${token('space.050', '4px')} -${token('space.025', '2px')} ${N40A}`,
	)}`,
});

const defaultBackground = css({
	backgroundColor: token('color.background.neutral', N20),
});

type Props = {
	renderListHeaderItem: RenderHeaderItem;
	renderChartHeaderItem: RenderHeaderItem;
	extendedContent?: ExtendedContentItems;
	renderExtendedContentHeader?: RenderExtendedContent;
};

const Header = ({
	extendedContent,
	renderListHeaderItem,
	renderChartHeaderItem,
	renderExtendedContentHeader,
}: Props) => {
	const [{ headerItems, headerHeight, isRowCountKnown }] = useHeaderState();
	const [isAtTop] = isScrollToTop();

	const shouldApplyVisualRefresh =
		// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
		fg('platform-component-visual-refresh') && fg('visual-refresh_drop_4');

	const hasExtendedContent =
		(renderExtendedContentHeader &&
			extendedContent &&
			extendedContent.length > 0 &&
			fg('timeline_table_extended_content')) ??
		false;

	return (
		<>
			<div
				role="rowgroup"
				css={[headerContainerStyles, !isAtTop && headerShadow]}
				style={{ height: headerHeight }}
			>
				{headerItems.map(({ height = DEFAULT_SUBHEADER_HEIGHT, id }: HeaderItem, index: number) => (
					<div
						key={id}
						data-testid={`roadmap.timeline-table.main.header.sub-header-${id.toLowerCase()}`}
						role="row"
						aria-rowindex={isRowCountKnown ? index + 1 : undefined}
						css={[
							shouldApplyVisualRefresh ? headerRowStyles : headerRowStylesOld,
							id === DEFAULT_HEADER_ROW_ID && defaultBackground,
						]}
						style={{ height }}
					>
						<ListHeaderCell
							id={id}
							itemHeight={height}
							renderListHeaderItem={renderListHeaderItem}
						/>
						{extendedContent && renderExtendedContentHeader && (
							<ExtendedContentHeader
								id={id}
								extendedContent={extendedContent}
								itemHeight={height}
								renderExtendedContentHeader={renderExtendedContentHeader}
							/>
						)}
						<ChartHeaderCell
							id={id}
							itemHeight={height}
							renderChartHeaderItem={renderChartHeaderItem}
						/>
					</div>
				))}
				<ScrollableOverlay isOverlayInHeader hasExtendedContent={hasExtendedContent} />
			</div>
		</>
	);
};

Header.displayName = 'Header';
Header.whyDidYouRender = true;

export default memo<Props>(Header);
