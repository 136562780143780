import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import DurationBadge from './duration-badge/index.tsx';
import messages from './messages.tsx';
import { getDaysInBetween } from './utils.tsx';

type Props = {
	fromDate: number;
	toDate: number;
	isSigned: boolean;
	isToDateExcluded: boolean;
};

const Duration = ({ fromDate, toDate, isSigned, isToDateExcluded }: Props) => {
	const sign = toDate < fromDate ? '-' : '+';
	const duration = getDaysInBetween(fromDate, toDate, isToDateExcluded);
	const { formatMessage } = useIntl();

	const visuallyRefreshedDuration =
		// eslint-disable-next-line no-nested-ternary
		isVisualRefreshEnabled() ? (
			duration !== 0 ? (
				<>
					{`${' '}`}
					{formatMessage(messages.duration, {
						durationSign: `${isSigned ? sign : ''}`,
						duration,
					})}
				</>
			) : null
		) : (
			<DurationBadge duration={duration} sign={isSigned ? sign : undefined} />
		);

	return duration ? visuallyRefreshedDuration : null;
};

Duration.defaultProps = {
	isSigned: false,
	isToDateExcluded: false,
};

export { Duration };
