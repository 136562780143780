import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	clearSprint: {
		id: 'roadmap.timeline-table-kit.chart-timeline-overlay.context-menu.sprint.clear-sprint',
		defaultMessage: 'Clear sprint',
		description:
			"A label for the dropdown item. When clicked it clears the issue's active and/or future sprint.",
	},
	active: {
		id: 'roadmap.timeline-table-kit.chart-timeline-overlay.context-menu.sprint.active',
		defaultMessage: 'Active',
		description: 'A label for the active sprint',
	},
});
