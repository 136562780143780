import React, { useMemo, useRef } from 'react';
import { jpdRoadmapOnTimelineButtonEntryPoint } from '@atlassian/jira-roadmap-on-timeline/button/entrypoint.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const JpdRoadmapExperimentButton = () => {
	if (!fg('grw-880_jpd_roadmap_on_jira_timeline_killswitch')) {
		return null;
	}

	return <JpdRoadmapExperimentButtonInternal />;
};

const JpdRoadmapExperimentButtonInternal = () => {
	const hasLoadedEntrypoint = useRef(false);
	const runtimeProps = useMemo(() => ({}), []);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		jpdRoadmapOnTimelineButtonEntryPoint,
		{},
	);

	if (!hasLoadedEntrypoint.current) {
		entryPointActions.load();
		hasLoadedEntrypoint.current = true;
	}

	return (
		<JiraEntryPointContainer
			id="growth.jpdRoadmapOnTimelineButton"
			packageName="jpd-roadmap-on-timeline-button"
			teamName="galileo-growth"
			runtimeProps={runtimeProps}
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={null}
		/>
	);
};
