import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import { saveAs } from 'file-saver';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { downloadText } from '@atlassian/jira-file-download/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getColumnTransformers, escapeValue } from './columns.tsx';
import type {
	ColumnTransformerContext,
	ColumnTransformers,
	GenerateCsvType,
	BusinessIssue,
} from './types.tsx';

const BASE_LEVEL_PARENT = '[NO_PARENT]';

export type onGenerateCsvAction = (props: GenerateCsvType) => void;

const generatePartialCsv = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
	parentId: string,
) => {
	const { chartDataHash, issuesByParent } = context;
	let csv = '';

	(issuesByParent[parentId] ?? []).forEach(({ issueId, issue }) => {
		const chartData = chartDataHash?.[issueId];
		csv += `${columnTransformers
			.map((column) =>
				column.transform(
					{
						issueId,
						issue,
						chartData,
					},
					context,
				),
			)
			.join(',')}\r\n`;

		csv += generatePartialCsv(columnTransformers, context, issueId);
	});

	return csv;
};

export const generateHeaders = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
) =>
	`${columnTransformers
		.map((column) => escapeValue(context.issueFields[column.fieldId ?? ''] ?? column.fieldId))
		.join(',')}\r\n`;

export const generateCsv = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
) => {
	let csv = '';

	csv += generateHeaders(columnTransformers, context);

	csv += generatePartialCsv(columnTransformers, context, BASE_LEVEL_PARENT);

	const safeFileName = context.projectName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
	const dateNow = moment().format('YYYY-MM-DD_hh.mma');

	if (fg('migrate_away_from_file-saver_package')) {
		downloadText(csv, `${safeFileName}_${dateNow}.csv`, 'text/plain;charset=utf-8');
	} else {
		const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
		saveAs(blob, `${safeFileName}_${dateNow}.csv`);
	}
};

export const onGenerateCsv = ({
	issueIds,
	issuesHash,
	userDisplayNameHash,
	issueTypesHash,
	startDateCustomFieldId,
	colorCustomFieldId,
	sprintCustomFieldId,
	issueFields,
	issueSprintsHash,
	chartDataHash,
	projectName,
	isSprintsFeatureEnabled = false,
	hasInferredStartDueDateFields = true,
}: GenerateCsvType) => {
	if (issueIds.length === 0) {
		return Observable.empty<never>();
	}

	const issuesByParent: Record<string, { issueId: IssueId; issue: Issue | BusinessIssue }[]> = {
		BASE_LEVEL_PARENT: [],
	};

	issueIds.forEach((issueId) => {
		const issue = issuesHash[issueId];
		const parentId = issue.parentId?.value ?? BASE_LEVEL_PARENT;
		const issueList = issuesByParent[parentId] ?? [];
		issueList.push({ issueId, issue });
		issuesByParent[parentId] = issueList;
	});

	const customFields = {
		startDateCustomFieldId,
		colorCustomFieldId,
		sprintCustomFieldId,
		isSprintsFeatureEnabled,
		hasInferredStartDueDateFields,
	};
	const columnTransformers = getColumnTransformers(customFields);

	const transformationContext: ColumnTransformerContext = {
		issueIds,
		issuesByParent,
		issuesHash,
		userDisplayNameHash,
		issueTypesHash,
		issueFields,
		issueSprintsHash,
		customFields,
		chartDataHash,
		projectName,
	};

	generateCsv(columnTransformers, transformationContext);
};
