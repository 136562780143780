import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { type Color, PURPLE } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { Placeholder } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import { RoadmapDependencyHandlers } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/bar-content/dependency-handler/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { createDependency } from '../../../../../../state/entities/issues/actions.tsx';
import { getChartDataHash } from '../../../../../../state/selectors/table/index.tsx';
import type { State } from '../../../../../../state/types.tsx';

type OwnProps = {
	isHovered: boolean;
	leftPosition: number;
	rightPosition: number;
	getBarBoundingClientRect: () => ClientRect | undefined;
	id: string;
	placeholder: Placeholder;
	onBarMouseLeave: () => void;
};

type StateProps = {
	color: Color;
};

const onCreateDependency = (from: string, to: string, analyticEvent: UIAnalyticsEvent) =>
	createDependency({ dependency: to, dependee: from, isDragged: true }, analyticEvent);

export default connect(
	(state: State, { id }: OwnProps): StateProps => {
		const chartData = getChartDataHash(state)[id];
		if (chartData === undefined) {
			log.safeErrorWithoutCustomerData(
				'roadmap.standard.view.chart-item-content.dependency-handler',
				`ChartData is undefined: issueId ${id}`,
			);
		}
		return {
			color: chartData?.color ?? PURPLE,
		};
	},
	{
		onCreateDependency,
	},
)(RoadmapDependencyHandlers);
