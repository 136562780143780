/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export const TooltipInterval = ({ children }: { children: React.ReactNode }) => (
	<Flex direction="row" alignItems="center" xcss={tooltipStyles}>
		{children}
	</Flex>
);

export const ContentName = ({ children }: { children: React.ReactNode }) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		})}
	>
		{children}
	</div>
);

export const ContentDateLabel = ({ children }: { children: React.ReactNode }) => (
	<div
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css={css({
			marginLeft: token('space.050'),
			color: token('color.text.inverse'),
		})}
	>
		{children}
	</div>
);

const tooltipStyles = xcss({
	whiteSpace: 'nowrap',
});
