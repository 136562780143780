import { useCallback } from 'react';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useFirePostOfficeTrigger } from '@atlassian/post-office-context';
import { fg } from '@atlassian/jira-feature-gating';
import { JPD_ROADMAP_ON_TIMELINE_SPOTLIGHT_TRIGGER } from './constants.tsx';

export const useHasJpd = () => {
	const licensedProducts = useLicensedProducts();
	return licensedProducts['jira-product-discovery'] === true;
};

export const useIsTimelineRoute = () => {
	const { name } = useCurrentRoute();
	return (
		[
			'classic-business-timeline', // JWM
			'software-timeline', // TMP
			'software-timeline-classic', // CMP
		].indexOf(name) >= 0
	);
};

export const useFireJpdRoadmapOnTimelineSpotlightTrigger = () => {
	const isTimelineRoute = useIsTimelineRoute();
	const { fireTrigger } = useFirePostOfficeTrigger({
		triggerId: JPD_ROADMAP_ON_TIMELINE_SPOTLIGHT_TRIGGER,
	});

	const triggerSpotlight = useCallback(() => {
		if (fg('grw-880_jpd_roadmap_on_jira_timeline_killswitch')) {
			if (isTimelineRoute) {
				fireTrigger({ isActive: true });
			}
		}
	}, [fireTrigger, isTimelineRoute]);

	return triggerSpotlight;
};

export const useResetJpdRoadmapOnTimelineSpotlightTrigger = () => {
	const { fireTrigger } = useFirePostOfficeTrigger({
		triggerId: JPD_ROADMAP_ON_TIMELINE_SPOTLIGHT_TRIGGER,
	});

	const triggerSpotlight = useCallback(() => {
		if (fg('grw-880_jpd_roadmap_on_jira_timeline_killswitch')) {
			fireTrigger({ isActive: false });
		}
	}, [fireTrigger]);

	return triggerSpotlight;
};
