import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { saveAs } from 'file-saver';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { onGenerateCsv } from '@atlassian/jira-software-roadmap-image-export/src/export/csv/index.tsx';
import { downloadText } from '@atlassian/jira-file-download/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	getFullIssueTypeHash,
	getStartDateCustomFieldId,
	getSprintCustomFieldId,
	getColorCustomFieldId,
	getFieldsNamesHash,
	getIsSprintsFeatureEnabled,
	getProjectName,
} from '../../../state/configuration/selectors.tsx';
import { getFullIssueHash } from '../../../state/entities/issues/selectors.tsx';
import { getUserDisplayNameHash } from '../../../state/entities/users/selectors.tsx';
import { generalError } from '../../../state/flags/actions.tsx';
import { getFilteredIssueIds } from '../../../state/selectors/issues/index.tsx';
import { getSanitisedIssueSprintsHash } from '../../../state/selectors/sprint/index.tsx';
import { getChartDataHash } from '../../../state/selectors/table/index.tsx';
import type { State } from '../../../state/types.tsx';
import { type ExportCsvAction as Action, EXPORT_CSV } from '../../../state/ui/share/actions.tsx';
import type { StateEpic } from '../../common/types.tsx';
import { escapeValue } from './columns.tsx';
import type { GenerateCsvType, ColumnTransformerContext, ColumnTransformers } from './types.tsx';

const BASE_LEVEL_PARENT = '[NO_PARENT]';

const generatePartialCsv = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
	parentId: string,
) => {
	const { chartDataHash, issuesByParent } = context;
	let csv = '';

	(issuesByParent[parentId] ?? []).forEach(({ issueId, issue }) => {
		const chartData = chartDataHash[issueId];
		csv += `${columnTransformers
			.map((column) =>
				column.transform(
					{
						issueId,
						issue,
						chartData,
					},
					context,
				),
			)
			.join(',')}\r\n`;

		csv += generatePartialCsv(columnTransformers, context, issueId);
	});

	return csv;
};

export const generateHeaders = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
) =>
	`${columnTransformers
		.map((column) => escapeValue(context.issueFields[column.fieldId ?? ''] ?? column.fieldId))
		.join(',')}\r\n`;

export const generateCsv = (
	columnTransformers: ColumnTransformers,
	context: ColumnTransformerContext,
) => {
	let csv = '';

	csv += generateHeaders(columnTransformers, context);

	csv += generatePartialCsv(columnTransformers, context, BASE_LEVEL_PARENT);

	const safeFileName = context.projectName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
	const dateNow = moment().format('YYYY-MM-DD_hh.mma');

	if (fg('migrate_away_from_file-saver_package')) {
		downloadText(csv, `${safeFileName}_${dateNow}.csv`, 'text/plain;charset=utf-8');
	} else {
		const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
		saveAs(blob, `${safeFileName}_${dateNow}.csv`);
	}
};

export const generate = ({
	issueIds,
	issuesHash,
	userDisplayNameHash,
	issueTypesHash,
	startDateCustomFieldId,
	colorCustomFieldId,
	sprintCustomFieldId,
	issueFields,
	issueSprintsHash,
	isSprintsFeatureEnabled,
	chartDataHash,
	projectName,
}: GenerateCsvType) => {
	onGenerateCsv({
		issueIds,
		issuesHash,
		userDisplayNameHash,
		issueTypesHash,
		startDateCustomFieldId,
		colorCustomFieldId,
		sprintCustomFieldId,
		issueFields,
		issueSprintsHash,
		isSprintsFeatureEnabled,
		chartDataHash,
		projectName,
	});
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(EXPORT_CSV)
		.mergeMap(({ payload: { inferredDueDateHeader, inferredStartDateHeader } }) => {
			try {
				const state = store.getState();
				const issueIds = getFilteredIssueIds(state);
				const issuesHash = getFullIssueHash(state);
				const userDisplayNameHash = getUserDisplayNameHash(state);
				const issueTypesHash = getFullIssueTypeHash(state);
				const startDateCustomFieldId = getStartDateCustomFieldId(state);
				const colorCustomFieldId = getColorCustomFieldId(state);
				const sprintCustomFieldId = getSprintCustomFieldId(state);
				const issueFields = {
					inferredDueDate: inferredDueDateHeader,
					inferredStartDate: inferredStartDateHeader,
					...getFieldsNamesHash(state),
				};
				const issueSprintsHash = getSanitisedIssueSprintsHash(state);
				const isSprintsFeatureEnabled = getIsSprintsFeatureEnabled(state);
				const chartDataHash = getChartDataHash(state);
				const projectName = getProjectName(state);

				generate({
					issueIds,
					issuesHash,
					userDisplayNameHash,
					issueTypesHash,
					startDateCustomFieldId,
					colorCustomFieldId,
					sprintCustomFieldId,
					issueFields,
					issueSprintsHash,
					isSprintsFeatureEnabled,
					chartDataHash,
					projectName,
				});
			} catch {
				log.safeErrorWithoutCustomerData(
					'jsw.timeline.export-csv.failure',
					'Failed to generate timeline CSV',
				);
				return Observable.of(generalError());
			}

			return Observable.empty<never>();
		})) as StateEpic;
