import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	changeColorMenuTitle: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.change-color-option.change-color-menu.change-color-menu-title',
		defaultMessage: 'Issue color',
		description: 'Title for change issue color menu',
	},
	changeColorMenuTitleIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.common.table.list-item-menu-option.change-color-option.change-color-menu.change-color-menu-title-issue-term-refresh',
		defaultMessage: 'Work item color',
		description: 'Title for change issue color menu',
	},
});
export default messages;
