import has from 'lodash/has';
import type { Color } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import type { getItemsByIdsQuery$data } from '@atlassian/jira-relay/src/__generated__/getItemsByIdsQuery.graphql';
import type { RoadmapScheduleItemInput } from '@atlassian/jira-relay/src/__generated__/scheduleRoadmapItemsMutation.graphql';
import type {
	Issue,
	SortedIssueHash,
	IssueScheduleFields,
} from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import type { SubtaskHash } from '@atlassian/jira-software-roadmap-model/src/subtask/index.tsx';
import type { UserHash } from '@atlassian/jira-software-roadmap-model/src/user/index.tsx';
import { optionalDateToUtcTimestamp, optionalUtcTimestampToDate } from '../common/date.tsx';
import {
	toModifiable,
	nullableToOptional,
	nullableToModifiable,
	nullableToModifiableWithConversion,
	optionalToNullableWithConversion,
} from '../common/field.tsx';
import { transformStatusCategories } from '../common/status-categories.tsx';
import { transformStatus } from '../status/transformers.tsx';
import { transformAssignee } from '../users/transformers.tsx';
import type {
	RawGraphQLIssue,
	IssuesAndUsers,
	SubtasksAndStatusCategories,
	GraphQLSubtasksAndStatusCategories,
} from './types.tsx';

export const transformIssue = (issue: RawGraphQLIssue): Issue => {
	const assignee = nullableToOptional(issue.assignee);
	const parentId = issue.parentId || null;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const color = issue.color as Color;
	const componentIds = issue.componentIds || [];

	return {
		key: issue.key,
		// @ts-expect-error(Suppressed for relay bump) - Type 'Modifiable<string | null | undefined>' is not assignable to type 'Modifiable<string>'.
		summary: toModifiable(issue.summary),
		startDate: nullableToModifiableWithConversion(
			issue.startDateRFC3339,
			optionalDateToUtcTimestamp,
		),
		dueDate: nullableToModifiableWithConversion(issue.dueDateRFC3339, optionalDateToUtcTimestamp),
		color: toModifiable(color),
		inCreation: false,
		issueTypeId: `${issue.itemTypeId}`,
		// @ts-expect-error(Suppressed for relay bump) - Type 'string | null | undefined' is not assignable to type 'string'.
		rank: issue.rank,
		status: transformStatus(issue.status || null),
		assignee: assignee?.accountId,
		parentId: nullableToModifiable(parentId),
		// @ts-expect-error(Suppressed for relay bump) - Type 'readonly string[] | null | undefined' must have a '[Symbol.iterator]()' method that returns an iterator.
		dependencies: [...issue.dependencies],
		labels: Array.isArray(issue.labels) ? [...issue.labels] : [],
		sprintIds: toModifiable([...issue.sprintIds]),
		versionIds: [...issue.versionIds],
		componentIds: [...componentIds],
		// @ts-expect-error(Suppressed for relay bump) - Type 'boolean | null | undefined' is not assignable to type 'boolean'.
		resolved: issue.resolved,
		flagged: issue.flagged || false,
	};
};

export const transformIssues = (graphQLIssues: RawGraphQLIssue[]): IssuesAndUsers => {
	const users: UserHash = {};
	const issues: SortedIssueHash = {
		hash: {},
		sequence: [],
	};

	graphQLIssues.forEach((issue: RawGraphQLIssue) => {
		const assignee = nullableToOptional(issue.assignee);

		if (assignee !== undefined) {
			users[assignee.accountId] = transformAssignee(assignee);
		}

		issues.sequence.push(issue.id);
		issues.hash[issue.id] = transformIssue(issue);
	});

	return {
		users,
		issues,
	};
};

export const transformIssuesFromGetItemsByIdsQueryResponse = (
	data: getItemsByIdsQuery$data,
): IssuesAndUsers => {
	const roadmapItems = data.roadmaps?.roadmapItemByIds;

	const users: UserHash = {};
	const issues: SortedIssueHash = {
		hash: {},
		sequence: [],
	};

	if (roadmapItems) {
		roadmapItems.forEach((issue) => {
			if (issue) {
				const assignee = nullableToOptional(issue.assignee);
				if (assignee !== undefined) {
					users[assignee.accountId] = transformAssignee(assignee);
				}
				issues.sequence.push(issue.id);
				issues.hash[issue.id] = transformIssue(issue);
			}
		});
	}

	return {
		users,
		issues,
	};
};

export const transformScheduleItemsRequest = (
	issueScheduleFields: IssueScheduleFields[],
): RoadmapScheduleItemInput[] => {
	const issues: RoadmapScheduleItemInput[] = [];

	issueScheduleFields.forEach((scheduledIssue) => {
		const itemId = scheduledIssue.issueId;
		let startDate = null;
		let dueDate = null;

		if (has(scheduledIssue, 'startDate')) {
			startDate = optionalToNullableWithConversion(
				scheduledIssue.startDate,
				optionalUtcTimestampToDate,
			);
		}
		if (has(scheduledIssue, 'dueDate')) {
			dueDate = optionalToNullableWithConversion(
				scheduledIssue.dueDate,
				optionalUtcTimestampToDate,
			);
		}

		issues.push({
			itemId,
			startDate,
			dueDate,
		});
	});

	return issues;
};

export const transformSubtasksAndStatusCategoriesResponse = (
	response: GraphQLSubtasksAndStatusCategories,
): SubtasksAndStatusCategories => {
	const { subtasks, statusCategories } = response;
	const subtasksHash: SubtaskHash = {};

	subtasks.forEach(({ parentId, id, key, statusCategoryId }) => {
		if (subtasksHash[parentId] === undefined) {
			subtasksHash[parentId] = [];
		}

		subtasksHash[parentId].push({
			id,
			key,
			statusCategoryId,
		});
	});

	return {
		statusCategories: transformStatusCategories([...statusCategories]),
		subtasks: subtasksHash,
	};
};
