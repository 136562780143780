import type { Dispatch } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	updateAndPersistIssue,
	type UpdateIssueAction,
} from '../../../../../../state/entities/issues/actions.tsx';
import { getIssue } from '../../../../../../state/entities/issues/selectors.tsx';
import { getParentOptions } from '../../../../../../state/selectors/list-item-menu-option/index.tsx';
import type { State } from '../../../../../../state/types.tsx';
import {
	changeExpansionState,
	type ChangeExpansionStateAction,
} from '../../../../../../state/ui/table/actions.tsx';
import type { DispatchProps, OwnProps, StateProps } from './types.tsx';
import ReparentMenuWrapper from './view.tsx';

type Action = UpdateIssueAction | ChangeExpansionStateAction;

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => ({
	issue: getIssue(state, id),
	parentOptions: getParentOptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>, { id }: OwnProps): DispatchProps => ({
	setParent: (parentId: string | undefined, analyticsEvent: UIAnalyticsEvent) => {
		if (parentId) {
			dispatch(changeExpansionState({ id: parentId, isExpanded: true }));
		}
		dispatch(updateAndPersistIssue(id, { parentId }, analyticsEvent));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ReparentMenuWrapper);
