import type { Color } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	Hash,
	IdentifiableHash,
} from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { RolledUpDates } from '../dates/index.tsx';
import { getBaseLevelChartData } from './base-level/index.tsx';
import type { ChartData, DateError } from './common/types.tsx';
import { getParentLevelChartData } from './parent-level/index.tsx';

export type ChartDataHash = IdentifiableHash<IssueId, ChartData>;

export const getChartDataHash = (
	parentIds: IssueId[],
	childIdsHash: Hash<IssueId[]>,
	issueStartDateHash: Hash<number | undefined>,
	issueDueDateHash: Hash<number | undefined>,
	issueSprintsHash: Hash<Sprint[]>,
	issueColorHash: Hash<Color>,
	issueDateErrorsHash: Hash<DateError[]>,
	parentRolledUpDatesHash: Hash<RolledUpDates>,
	issueParentIdHash: Hash<IssueId | undefined>,
	isSprintsPlanning: boolean,
	isChildIssuePlanningEnabled: boolean,
): ChartDataHash => {
	const chartDataHash: ChartDataHash = {};

	parentIds.forEach((parentId) => {
		chartDataHash[parentId] = getParentLevelChartData(
			issueColorHash,
			issueStartDateHash,
			issueDueDateHash,
			parentRolledUpDatesHash,
			issueDateErrorsHash,
			parentId,
			isChildIssuePlanningEnabled,
		);

		const childIds = childIdsHash[parentId] || [];
		childIds.forEach((childId) => {
			chartDataHash[childId] = getBaseLevelChartData(
				isSprintsPlanning,
				isChildIssuePlanningEnabled,
				issueParentIdHash,
				issueColorHash,
				issueStartDateHash,
				issueDueDateHash,
				issueSprintsHash,
				issueDateErrorsHash,
				childId,
			);
		});
	});

	return chartDataHash;
};
