/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ComponentPropsWithoutRef, memo, type ReactElement, useRef } from 'react';

import { css, jsx } from '@compiled/react';

import { useEnablements } from '../../common/context/enablements';
import { useHeaderState } from '../../common/context/header';
import { useTimelineState } from '../../common/context/timeline';
import type { TimelineMode } from '../../common/types/timeline';
import type { RenderChartTimelineOverlay } from '../../renderers';

import ColumnsOverlay from './columns-overlay';
import Cover from './cover';
import TodayMarker from './today-marker';

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	bottom: 0,
	left: 0,
	width: '100%',
	pointerEvents: 'none',
});

const chartContainerStyles = css({
	flex: '0 0 auto',
});

type ColumnOverlayElements = Record<
	TimelineMode,
	ReactElement<ComponentPropsWithoutRef<typeof ColumnsOverlay>>
>;

type HeaderOverlayProps = {
	isOverlayInHeader: true;
	renderChartTimelineOverlay?: never;
};

type ContentOverlayProps = {
	isOverlayInHeader?: false;
	renderChartTimelineOverlay: RenderChartTimelineOverlay;
};

type Props = (HeaderOverlayProps | ContentOverlayProps) & { hasExtendedContent: boolean };

const ScrollableOverlay = ({
	isOverlayInHeader,
	renderChartTimelineOverlay,
	hasExtendedContent,
}: Props) => {
	const columnsOverlayElementsRef = useRef<Partial<ColumnOverlayElements>>({});
	const [{ today, timelineMode, timelineDuration, columnDurations, timelineWidth }] =
		useTimelineState();
	const [{ headerHeight }] = useHeaderState();
	const [{ isHighlightToday }] = useEnablements();

	// NOTE: Optimisation! There are enough columns that caching the generated elements is worth it.
	if (!columnsOverlayElementsRef.current[timelineMode]) {
		columnsOverlayElementsRef.current[timelineMode] = (
			<ColumnsOverlay
				isHighlightToday={isHighlightToday}
				today={today}
				timelineDuration={timelineDuration}
				columnDurations={columnDurations}
			/>
		);
	}

	return (
		<div css={containerStyles}>
			<Cover type="list" />
			{hasExtendedContent && <Cover type="extendedContent" />}
			<div
				css={chartContainerStyles}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					...(isOverlayInHeader
						? { position: 'sticky', top: 0 }
						: {
								position: 'relative',
								top: `${headerHeight}px`,
								height: `calc(100% - ${headerHeight}px)`,
							}),
					width: `${timelineWidth}px`,
				}}
			>
				{columnsOverlayElementsRef.current[timelineMode]}
				{!isOverlayInHeader && renderChartTimelineOverlay()}
				{!isOverlayInHeader && isHighlightToday && (
					<TodayMarker
						today={today}
						timelineMode={timelineMode}
						timelineDuration={timelineDuration}
					/>
				)}
			</div>
		</div>
	);
};

export default memo<Props>(ScrollableOverlay);
