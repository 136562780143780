/** @jsx jsx */
import React, { useCallback, type Ref, type MouseEvent, type KeyboardEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates.tsx';
import { ACTIVE } from '../../../../common/constants/interval.tsx';
import { Title, ActionButton } from '../../../../common/ui/flyout/styled.tsx';
import { SprintIcon } from '../../../../common/ui/sprint-icon/index.tsx';
import messages from './messages.tsx';

const sprintActionButtonStyles = css({
	color: token('color.text.subtle'),
	display: 'flex',
});

const sprintDetailsStyles = css({
	overflow: 'hidden',
});

const sprintDatesStyles = css({
	font: token('font.body.small'),
	color: token('color.text.subtlest'),
});

const sprintNameWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.025'),
});

const sprintNameStyles = css({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: token('space.250'),
});

type Props = {
	initialFocusRef?: Ref<HTMLInputElement>;
	title?: string;
	showClearOption: boolean;
	showClearOptionSeparator?: boolean;
	sprints: Sprint[];
	onUpdateSprint: (event: MouseEvent | KeyboardEvent, sprintId: string | undefined) => void;
};

const SprintMenu = ({
	initialFocusRef,
	title,
	showClearOption,
	showClearOptionSeparator = false,
	sprints,
	onUpdateSprint,
}: Props) => {
	const { formatMessage } = useIntl();

	const createOnMoveToSprint = useCallback(
		(sprintId?: string) => (event: MouseEvent | KeyboardEvent) => {
			onUpdateSprint(event, sprintId);
		},
		[onUpdateSprint],
	);

	const clearOptionItem = (
		<DropdownItem
			key="clearSprint"
			{...(showClearOption ? { ref: initialFocusRef } : undefined)}
			onClick={createOnMoveToSprint(undefined)}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-context-menu-cleared-sprint' }
				: {})}
		>
			<ActionButton>{formatMessage(messages.clearSprint)}</ActionButton>
		</DropdownItem>
	);
	let clearOption = null;

	if (showClearOption) {
		clearOption =
			showClearOptionSeparator && sprints.length > 0 ? (
				<Box xcss={clearOptionWrapperStyles}>{clearOptionItem}</Box>
			) : (
				clearOptionItem
			);
	}

	return (
		<div
			data-testid="roadmap.timeline-table-kit.ui.chart-timeline-overlay.context-menu.sprint"
			css={[containerContentStyles]}
		>
			{title ? <Title>{title}</Title> : null}
			{clearOption}
			{sprints.map((sprint: Sprint, index: number) => (
				<DropdownItem
					key={sprint.id}
					{...(!showClearOption && index === 0 ? { ref: initialFocusRef } : undefined)}
					onClick={createOnMoveToSprint(sprint.id)}
					{...(fg('platform_button_item-add-ufo-metrics')
						? { interactionName: 'timeline-context-menu-changed-sprint' }
						: {})}
				>
					<div css={sprintActionButtonStyles}>
						<Box xcss={sprintIconWrapperStyles}>
							<SprintIcon />
						</Box>
						<div css={sprintDetailsStyles}>
							<div css={sprintNameWrapperStyles}>
								<div css={sprintNameStyles}>{sprint.name}</div>
								{sprint.state === ACTIVE && (
									<Box xcss={sprintStatusStyles}>
										{' '}
										<Lozenge appearance="inprogress">{formatMessage(messages.active)}</Lozenge>
									</Box>
								)}
							</div>
							<div css={sprintDatesStyles}>
								{`${getDateFromTimestamp(
									sprint.startDate,
								)} - ${getDateFromTimestamp(sprint.endDate)}`}
							</div>
						</div>
					</div>
				</DropdownItem>
			))}
		</div>
	);
};

const containerContentStyles = css({
	width: '260px',
	maxHeight: '244px',
	backgroundColor: token('elevation.surface.overlay'),
	padding: 0,
	overflowY: 'auto',
	boxShadow: token('elevation.shadow.overlay'),
});

const clearOptionWrapperStyles = xcss({
	borderBottom: '2px solid',
	borderColor: 'color.border',
});

const sprintStatusStyles = xcss({
	marginLeft: 'space.100',
});

const sprintIconWrapperStyles = xcss({
	marginTop: 'space.025',
});

export { SprintMenu };
