import React from 'react';
import SprintIcon from '@atlaskit/icon/core/migration/sprint';
import { token } from '@atlaskit/tokens';
import LegendItem from '@atlassian/jira-aais-timeline-legend/src/ui/legend-item/index.tsx';
import LegendSection from '@atlassian/jira-aais-timeline-legend/src/ui/legend-section/index.tsx';
import LegendComponent from '@atlassian/jira-aais-timeline-legend/src/ui/legend/index.tsx';
import DependenciesIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/dependencies-icon/index.tsx';
import IssueBarIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/issue-bar-icon/index.tsx';
import ReleaseStatusIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/release-status-icon/index.tsx';
import RollupIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/rollup-icon/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const Legend = ({ isHidden }: { isHidden?: boolean }) => {
	const { formatMessage } = useIntl();

	const sprintsMessage = formatMessage(messages.sprints);
	const dependencyMessage = formatMessage(messages.dependency);
	const rollupMessage = formatMessage(messages.rollup);

	const noEndDateMessage = formatMessage(messages.noEndDate);
	const noStartDateMessage = formatMessage(messages.noStartDate);

	const releasedMessage = formatMessage(messages.released);
	const unreleasedMessage = formatMessage(messages.unreleased);
	const overdueMessage = formatMessage(messages.overdue);

	return (
		<LegendComponent {...(fg('jsw_roadmaps_timeline-fix-a11y-rain') ? { isHidden } : {})}>
			<LegendSection title={formatMessage(messages.general)}>
				<LegendItem
					symbol={
						<SprintIcon
							LEGACY_size="medium"
							label={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? '' : sprintsMessage}
							color={token('color.text.subtle')}
							spacing="spacious"
						/>
					}
					label={sprintsMessage}
				/>
				<LegendItem
					symbol={
						<DependenciesIcon
							label={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? '' : dependencyMessage}
						/>
					}
					label={dependencyMessage}
				/>
				<LegendItem
					symbol={
						<RollupIcon label={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? '' : rollupMessage} />
					}
					label={rollupMessage}
				/>
			</LegendSection>
			<LegendSection
				title={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.issueBarsIssueTermRefresh
						: messages.issueBars,
				)}
			>
				<LegendItem symbol={<IssueBarIcon type="noEndDate" />} label={noEndDateMessage} />
				<LegendItem symbol={<IssueBarIcon type="noStartDate" />} label={noStartDateMessage} />
			</LegendSection>
			<LegendSection title={formatMessage(messages.releases)}>
				<LegendItem symbol={<ReleaseStatusIcon type="released" />} label={releasedMessage} />
				<LegendItem symbol={<ReleaseStatusIcon type="unreleased" />} label={unreleasedMessage} />
				<LegendItem symbol={<ReleaseStatusIcon type="overdue" />} label={overdueMessage} />
			</LegendSection>
		</LegendComponent>
	);
};

export default Legend;
