import React from 'react';

import noop from 'lodash/noop';

import { DEFAULT_ITEM_HEIGHT, DEFAULT_LIST_WIDTH } from './common/constants';
import { EnablementProvider } from './common/context/enablements';
import HeaderProvider from './common/context/header';
import { ItemsProvider } from './common/context/items';
import ListProvider from './common/context/list';
import PortalScopeProvider from './common/context/portal/provider';
import SideEffectMarshal from './common/context/side-effect-marshal';
import type { MarshalOptions } from './common/context/side-effect-marshal/types';
import { TimelineProviderSweetState } from './common/context/timeline';
import { TimelineViewportProvider } from './common/context/timeline-viewport';
import type {
	OnCreateClick,
	OnItemActivate,
	OnItemDragStart,
	OnItemDrop,
	OnItemExpandChanged,
	OnItemMultiSelect,
	OnItemSelect,
	OnSetExtendedContentWidth,
	OnSetListWidth,
} from './common/types/callbacks';
import type { CreateItemAnchor } from './common/types/create';
import type { DndLongTaskMetricComponent } from './common/types/drag-and-drop';
import type { HeaderItem } from './common/types/header';
import type { ExpandedItems, Item, ItemId, ListItemMenuOptions } from './common/types/item';
import type { TimelineDuration, TimelineMode } from './common/types/timeline';
import Main from './main';
import type { Renderers } from './renderers';

interface APIProps {
	/** Enable the virtualisation of table rows */
	isVirtualiseEnabled: boolean;
	/** Determines whether to show visual elements derived from the today prop (e.g. the today marker) */
	isHighlightToday: boolean;
	/** Determines whether to hide the custom scrollbars in the chart (scrolling will still be enabled) */
	isHideScrollbar: boolean;
	/** Enables creation of a new table row in the last position */
	isCreateLastEnabled: boolean;
	/** Enables creation of a new table row between other rows */
	isCreateSiblingEnabled: boolean;
	/** Enables meatballs button on each row */
	isMeatballsButtonEnabled?: boolean;
	/** Enables creation of a new table row inside of another */
	isCreateChildEnabled: boolean;
	/** Enables resizing the list column */
	isResizeEnabled: boolean;
	/** Enable dragging and dropping table rows */
	isDragEnabled: boolean;
	/** Enables highlighting one or more table rows via user interactions */
	isSelectEnabled: boolean;
	/** Enables highlighting multiple table rows via user interactions. Also tied to assistive technology like aria-multiselectable */
	isMultiSelectEnabled: boolean;
	/** A namespace to differentiate multiple instances of the table on any one page */
	scope: string;
	/** Structure of the rows to be shown in the table body */
	items: Item[];
	/** Structure of the rows to be shown in the table header */
	headerItems: HeaderItem[];
	/** The currently active item */
	activeItemId: ItemId | undefined;
	/** The currently selected table rows. Alters row appearance */
	selectedItemIds: ItemId[];
	/** Controls the granularity of the timeline, with each column representing one unit of time (e.g. weeks) */
	timelineMode: TimelineMode;
	/** The start and end date of the timeline, providing boundaries for the timeline UI */
	timelineDuration: TimelineDuration;
	/** The date in milliseconds to show in the viewport when the timeline renders. Automatically adjusts based on timeline mode */
	timelineOrigin: number;
	/** Additional configuration for the side effect marshal */
	marshalOptions: MarshalOptions;
	/** Milliseconds since the UNIX epoch. Minor visual elements on the timeline depend on this value */
	today: number;
	/** A lookup of the expansion state for each row */
	expandedItems: ExpandedItems;
	/** The position of the row currently being created */
	createItemAnchor: CreateItemAnchor;
	/** Height of the parent level row */
	itemHeight: number;
	/** A set of renderer functions for consumers to inject their own behaviours into the table */
	renderers: Renderers;
	/** Width of the resizable list column */
	listWidth: number;
	/** Customized list item options for meatballs menu */
	listItemMenuOptions: ListItemMenuOptions;
	/** Diverge the drag and drop behavior: default behavior or tree item behavior  */
	isTreeDrag?: boolean;
	/** Fires when dragging a row ends */
	onItemDrop: OnItemDrop;
	/** Fires when selection is enabled and a user clicks on a row in the list column */
	onItemSelect: OnItemSelect; // Remove this with project_timeline_multi-select_and_checkboxes
	/** Fires when an item requests to be active. */
	onItemActivate: OnItemActivate;
	/** Callbacks with the requested list if item IDs to be selected. */
	onItemMultiSelect: OnItemMultiSelect;
	/** Fires when a user clicks on the collapse/expand button for a row */
	onItemExpandChanged: OnItemExpandChanged;
	/** Fires when a user clicks on a create row button */
	onCreateClick: OnCreateClick;
	/** Fires when a user finished resizing the list column */
	onSetListWidth: OnSetListWidth;
	/** Fires when a user finished resizing the extended content section */
	onSetExtendedContentWidth: OnSetExtendedContentWidth;
	/** Fires when dragging a row starts */
	onItemDragStart?: OnItemDragStart;
	/** Metric to measure drag and drop long tasks time */
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
	/** For assistive technology. Only provide this property with value -1 if the total number of rows in your table is unknown and can't be deducted from the row items */
	ariaRowCount: -1;
	/** For assistive technology. Should succinctly describe what your table is for */
	ariaLabel: string;
	/** For assistive technology. It is not necessary to provide this property if your table is only ever read only */
	ariaReadOnly: boolean;
}

const EMPTY_ARRAY: ItemId[] = [];

export default function TimelineTable(props: APIProps) {
	const {
		marshalOptions,
		isCreateLastEnabled,
		isCreateChildEnabled,
		isCreateSiblingEnabled,
		isDragEnabled,
		isSelectEnabled,
		isMultiSelectEnabled,
		isHighlightToday,
		listWidth,
		items,
		headerItems,
		itemHeight,
		activeItemId,
		onItemActivate,
		selectedItemIds,
		onItemMultiSelect,
		expandedItems,
		today,
		timelineMode,
		timelineDuration,
		timelineOrigin,
		createItemAnchor,
		ariaRowCount,
		ariaLabel,
		ariaReadOnly,
		isTreeDrag = false,
		listItemMenuOptions,
		isMeatballsButtonEnabled = false,
		...mainProps
	} = props;
	const safeSelectedItemIds = isSelectEnabled ? selectedItemIds : EMPTY_ARRAY;

	return (
		<PortalScopeProvider>
			<TimelineViewportProvider>
				<EnablementProvider
					isDragEnabled={isDragEnabled}
					isSelectEnabled={isSelectEnabled}
					isMultiSelectEnabled={isSelectEnabled && isMultiSelectEnabled}
					isCreateLastEnabled={isCreateLastEnabled}
					isCreateSiblingEnabled={isCreateSiblingEnabled}
					isCreateChildEnabled={isCreateChildEnabled}
					isMeatballsButtonEnabled={isMeatballsButtonEnabled}
					isHighlightToday={isHighlightToday}
					isTreeDrag={isTreeDrag}
				>
					<HeaderProvider headerItems={headerItems} isRowCountKnown={ariaRowCount !== -1}>
						<ItemsProvider
							items={items}
							createItemAnchor={createItemAnchor}
							isCreateLastEnabled={isCreateLastEnabled}
							isCreateSiblingEnabled={isCreateSiblingEnabled}
							isCreateChildEnabled={isCreateChildEnabled}
							expandedItems={expandedItems}
							// Change to selectedItemIds={selectedItemIds} when cleaning up project_timeline_multi-select_and_checkboxes
							selectedItemIdsWithPermission={isSelectEnabled ? selectedItemIds : undefined}
							onItemMultiSelect={onItemMultiSelect}
							activeItemId={activeItemId}
							onItemActivate={onItemActivate}
							itemHeight={itemHeight}
						>
							<TimelineProviderSweetState
								timelineMode={timelineMode}
								timelineDuration={timelineDuration}
								timelineOrigin={timelineOrigin}
								today={today}
							>
								<ListProvider listWidth={listWidth} listItemMenuOptions={listItemMenuOptions}>
									<SideEffectMarshal
										scope={mainProps.scope}
										ariaLabel={ariaLabel}
										ariaReadOnly={ariaReadOnly}
										ariaMultiselectable={isSelectEnabled ? isMultiSelectEnabled : undefined} // TODO: to be removed with FG project_timeline_multi-select_and_checkboxes
										{...marshalOptions}
									>
										<Main {...mainProps} selectedItemIds={safeSelectedItemIds} />
									</SideEffectMarshal>
								</ListProvider>
							</TimelineProviderSweetState>
						</ItemsProvider>
					</HeaderProvider>
				</EnablementProvider>
			</TimelineViewportProvider>
		</PortalScopeProvider>
	);
}

TimelineTable.defaultProps = {
	isVirtualiseEnabled: false,
	isCreateLastEnabled: true,
	isCreateSiblingEnabled: true,
	isCreateChildEnabled: true,
	isDragEnabled: true,
	isSelectEnabled: true,
	isMultiSelectEnabled: false,
	isResizeEnabled: true,
	isHighlightToday: true,
	isHideScrollbar: false,
	items: [],
	headerItems: [],
	expandedItems: {},
	renderers: {},
	marshalOptions: {},
	createItemAnchor: undefined,
	activeItemId: undefined,
	selectedItemIds: [],
	itemHeight: DEFAULT_ITEM_HEIGHT,
	listWidth: DEFAULT_LIST_WIDTH(),
	DndLongTaskMetric: undefined,
	onItemSelect: noop,
	onItemMultiSelect: noop,
	onItemActivate: noop,
	onItemDrop: noop,
	onCreateClick: noop,
	onItemExpandChanged: noop,
	onSetListWidth: noop,
	onSetExtendedContentWidth: noop,
	ariaRowCount: undefined,
	ariaLabel: undefined,
	ariaReadOnly: undefined,
	listItemMenuOptions: {
		overrideLabels: {},
		customSections: [],
	},
};

export type { APIProps };
