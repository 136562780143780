import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const Fallback = () => null;

export const jpdRoadmapOnTimelineSpotlightEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(async () => {
		if (!__SERVER__) {
			return import(
				/* webpackChunkName: "async-jira-roadmap-on-timeline-spotlight" */ './src/index'
			).then(({ JpdRoadmapOnTimelineSpotlight }) => JpdRoadmapOnTimelineSpotlight);
		}
		return Fallback;
	}),
	getPreloadProps: () => ({}),
});
