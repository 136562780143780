import React from 'react';
import FlagFilledIcon from '@atlaskit/icon/core/migration/flag-filled';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

// TODO: To be refactored by Jonathan Yeung to delete this duplicate and leverage the component in src/packages/software/roadmap/timeline-table/src/main/content/list/list-item/flag-icon
export const FlagIcon = () => {
	const { formatMessage } = useIntl();

	return (
		<Box xcss={flagSectionStyles}>
			<FlagFilledIcon
				label={formatMessage(messages.flagged)}
				color={token('color.icon.danger')}
				LEGACY_size="small"
			/>
		</Box>
	);
};

const flagSectionStyles = xcss({
	marginLeft: 'space.100',
});
