import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

export const getRoadmapSettingsUrl = (
	isCMP: boolean,
	projectKey: ProjectKey,
	boardId: BoardId,
): string => {
	const tmpRoadmapConfigUrl = `/jira/software/projects/${projectKey}/settings/boards/${boardId}/timeline`;
	let cmpRoadmapConfigUrl = `/jira/software/c/projects/${projectKey}/boards/${boardId}?config=timelineConfig`;

	if (
		(fg('blu-7261-change-blu-5799-to-support-nav3') || getWillShowNav4()) && // Delete line when cleaning up blu-7261
		fg('blu-5799-jira-software-board-entrypoint-route')
	) {
		cmpRoadmapConfigUrl = `/jira/software/c/projects/${projectKey}/boards/${boardId}/settings/timelineConfig`;
	}
	return isCMP ? cmpRoadmapConfigUrl : tmpRoadmapConfigUrl;
};
