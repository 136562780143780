import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.title',
		defaultMessage:
			"Changes are saved, but {numIssues, plural, one {issue isn't} other {issues aren't}} visible",
		description: "Flag title when created or updated issues aren't visible to the user",
	},
	flagDescriptionWithLink: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.flag-description-with-link',
		defaultMessage:
			'Changes were made to issue {linkStart}{linkEnd}, which is currently hidden on your timeline because of a filter or your view settings.',
		description: "Flag description when created or updated issue isn't visible to the user",
	},
	flagDescription: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.flag-description',
		defaultMessage:
			'Changes were made to issues currently hidden on your timeline because of a filter or your view settings.',
		description:
			"Flag description when multiple issues are created or updated but aren't visible to the user",
	},
	clearFilter: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.clear-filter',
		defaultMessage: 'Clear filter',
		description: 'Clear filter action caption',
	},
	issueLink: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.issue-link',
		defaultMessage: 'View {numIssues, plural, one {issue} other {issues}}',
		description: 'Link to the issue',
	},
	titleIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.title-issue-term-refresh',
		defaultMessage:
			"Changes are saved, but {numIssues, plural, one {work item isn't} other {work items aren't}} visible",
		description: "Flag title when created or updated issues aren't visible to the user",
	},
	flagDescriptionWithLinkIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.flag-description-with-link-issue-term-refresh',
		defaultMessage:
			'Changes were made to work item {linkStart}{linkEnd}, which is currently hidden on your timeline because of a filter or your view settings.',
		description: "Flag description when created or updated issue isn't visible to the user",
	},
	flagDescriptionIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.flag-description-issue-term-refresh',
		defaultMessage:
			'Changes were made to work currently hidden on your timeline because of a filter or your view settings.',
		description:
			"Flag description when multiple issues are created or updated but aren't visible to the user",
	},
	issueLinkIssueTermRefresh: {
		id: 'roadmap.standard-roadmap.flags.issue-hidden.issue-link-issue-term-refresh',
		defaultMessage: 'View{numIssues, plural, one {} other { work items}}',
		description: 'Link to the issue',
	},
});
