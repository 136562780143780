import memoizeOne from 'memoize-one';

import { fg } from '@atlaskit/platform-feature-flags';

export const getScrollbarSize = (scrollElement: HTMLElement) =>
	scrollElement.offsetWidth - scrollElement.clientWidth;

export const getScrollOffsetRatio = (
	scrollOffset: number,
	scrollSize: number,
	clientSize: number,
): number => scrollOffset / (scrollSize - clientSize);

export const getContentToViewportRatio = (scrollSize: number, clientSize: number) =>
	clientSize / scrollSize;

export const getViewportOffsets = memoizeOne(
	(
		scrollbarSize: number,
		listWidth: number,
		extendedContentWidth: number,
		timelineTop: number,
	) => ({
		scrollbar: scrollbarSize,
		timelineLeft: fg('timeline_table_extended_content')
			? listWidth + extendedContentWidth
			: listWidth,
		timelineTop,
	}),
);
