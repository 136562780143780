/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export type AnchorProps = {
	x: number;
	y: number;
};

const titleStyles = css({
	font: token('font.body.small'),
	color: token('color.text.subtlest'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	marginTop: token('space.200'),
	marginRight: token('space.250'),
	marginBottom: token('space.100'),
	marginLeft: token('space.250'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
});

export const Title = ({ children }: { children: React.ReactNode }) => (
	<div css={titleStyles}>{children}</div>
);

const defaultStyles = css({ color: token('color.text.subtle') });
const disabledStyles = css({ color: token('color.text.disabled') });

export const ActionButton = ({
	isDisabled,
	children,
}: {
	isDisabled?: boolean;
	children: React.ReactNode;
}) => <div css={[!isDisabled && defaultStyles, isDisabled && disabledStyles]}>{children}</div>;
