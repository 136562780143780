import type { CreateItemAnchor } from '../../../common/types/create';
import type { ItemId } from '../../../common/types/item';
import { isRoot } from '../../../common/utils/hierarchy-enriched-items';

type CreateCheckParams = {
	id: ItemId;
	createItemAnchor: CreateItemAnchor;
	isInTransition: boolean;
};

type SiblingCreateCheckParams = CreateCheckParams & {
	isCreateChildEnabled: boolean;
	isCreateSiblingEnabled: boolean;
	index: number;
	depth: number;
};

type ChildCreateCheckParams = CreateCheckParams & {
	isCreateChildEnabled: boolean;
	canCreateChildOverride: boolean;
};

export const canItemCreateSibling = ({
	index,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	depth,
	...createCheckParams
}: SiblingCreateCheckParams) =>
	isCreateSiblingEnabled &&
	!createCheckParams.isInTransition &&
	((isRoot(depth) && index !== 0) || (!isRoot(depth) && isCreateChildEnabled));

export const canItemCreateChild = ({
	isCreateChildEnabled,
	canCreateChildOverride,
	...createCheckParams
}: ChildCreateCheckParams) =>
	isCreateChildEnabled && canCreateChildOverride && !createCheckParams.isInTransition;
